import { doRequest } from "@src/services/api";
import { serviceConstants } from "@utils/apiService";

class QrCodeLoginService {
    fetchQRLoginDetails(qrCodeId) {
        return doRequest(serviceConstants.fetchQRLoginDetails(qrCodeId))
    }

    validateQRLoginCode(qrCodeId, redirectCode,) {
        return doRequest(serviceConstants.validateQRLoginCode(qrCodeId, redirectCode,))
    }
}

const QrCodeLoginServiceInstance = new QrCodeLoginService();
export default QrCodeLoginServiceInstance;
