import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";

import Button from "@common/Buttons";
import { openPopup } from "@common/Modal/action";
import loginLogo from "@assets/images/bingeLogo.svg";
import { fetchQRLoginDetails, setQRCodeId, setQRLoginJourneyData } from "./APIs/actions";
import { MODALS } from "@common/Modal/constants";
import { closeQrLoginBottomSheet, handleSignInToTV, loginInNotSuccessFulToast } from "@containers/Login/LoginCommon";
import { URL } from "@constants/routeConstants";
import { safeNavigation } from "@utils/common";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import "./style.scss";

const QrLogin = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	// Local state to manage login details and error
	const [qrLoginScreenDetails, setQrLoginScreenDetails] = useState({});

	const getQueryParams = (query) => {
		const params = new URLSearchParams(query);
		return params.get("qrCodeId");
	};

	// Function to fetch login details
	const getLoginDetails = () => {
		// Extract qrCodeId from the query string
		const qrCodeId = getQueryParams(location.search);
		dispatch(fetchQRLoginDetails(qrCodeId)).then((response) => {
			if (response?.code === 0) {
				// Success: Set login details and clear any previous errors
				dispatch(setQRCodeId(qrCodeId));
				dispatch(setQRLoginJourneyData({
					sourcePlatform: response?.data?.sourcePlatform,
					loginMode: response?.data?.loginMode,
				}));
				setQrLoginScreenDetails(response?.data);
				mixPanelConfig.trackEvent(MIXPANEL.EVENT.QR_CODE_SCANNED, {
					[MIXPANEL.PARAMETER.SOURCE_PLATFORM]: response?.data?.sourcePlatform,
				})
			} else {
				// Error: Open the alert modal with error details
				closeQrLoginBottomSheet();
				dispatch(
					openPopup(MODALS.ALERT_MODAL, {
						modalClass: "alert-modal error-state-modal",
						headingMessage: "Something Went Wrong",
						instructions: response?.message ? response?.message : "The operation couldn’t be completed.",
						primaryButtonText: "Ok",
						errorIcon: "icon-alert-upd",
						closeModal: true,
						hideCloseIcon: true,
						errorCodeInstruction: `Error code: ${response?.code}`,
						primaryButtonAction: () => {
							history && safeNavigation(history, URL.DEFAULT);
						}
					})
				);
			}
		}).catch((error) => {
			// Catch block for unexpected errors
			console.error("Error fetching login details:", error);
		});
	};

	const handleCancelCta = () => {
		mixPanelConfig.trackEvent(MIXPANEL.EVENT.QR_CONFIRM_EXIT, {
			[MIXPANEL.PARAMETER.SOURCE_PLATFORM]: qrLoginScreenDetails?.sourcePlatform,
		})
		loginInNotSuccessFulToast();
		closeQrLoginBottomSheet();
		safeNavigation(history, URL.DEFAULT);
	}

	// Fetch login details on component mount
	useEffect(() => {
		getLoginDetails();
	}, []);

	return !isEmpty(qrLoginScreenDetails) && (
		<div className="qr-login-container">
			<div className="header-logo">
				<img src={loginLogo} alt="tata-sky-logo" />
			</div>
			<div className="login-license">
				<p className="heading">{qrLoginScreenDetails?.signInHeading}</p>
				<p className="sub-heading">{qrLoginScreenDetails?.signInSubHeading}</p>
			</div>
			<div className="redirect-code">
				<p>{qrLoginScreenDetails?.redirectCode}</p>
			</div>
			<div className="button-container">
				<Button
					cName="btn sign-in-to-tv-btn"
					bType="button"
					bValue={qrLoginScreenDetails?.signInCTA}
					clickHandler={() => {
						mixPanelConfig.trackEvent(MIXPANEL.EVENT.QR_CONFIRM_PROCEED, {
							[MIXPANEL.PARAMETER.SOURCE_PLATFORM]: qrLoginScreenDetails?.sourcePlatform,
						})
						handleSignInToTV(history)
					}}
				/>
				<div
					className="cancel-cta"
					onClick={() => handleCancelCta()}
				>
					{qrLoginScreenDetails?.cancelCTA}
					<div>
					</div>
				</div>
			</div>
			<p className="footer-text">{qrLoginScreenDetails?.signInFooter}</p>
		</div>
	);
};

export default QrLogin;