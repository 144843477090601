import { ACTION } from "./constants";
import get from "lodash/get";

export default function qrLoginReducer(state = {}, action) {
  switch (action.type) {
    case ACTION.FETCH_QRLOGIN_DETAILS:
      return {
        ...state,
        qrCodeLoginScreenDetail: get(action, "apiResponse", {}),
      };
    case ACTION.VALIDATE_QR_LOGIN_CODE:
      return {
        ...state,
        validationQRCodeResponse: get(action, "apiResponse", {}),
      };
    case ACTION.SHOW_QR_CODE_SCREEN:
      return { ...state, showQrCodeScreen: get(action, 'value') };
    case ACTION.SET_QR_CODE_ID:
      return { ...state, qrCodeId: get(action, 'value') };
    case ACTION.SET_QR_CODE_DATA:
      return { ...state, qrCodeJourney: get(action, 'payload') };
    case ACTION.RESET_QR_LOGIN_DETAILS:
      return {};
    default:
      return state;
  }
}