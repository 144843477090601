import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { getKey } from "@utils/storage";
import { LOCALSTORAGE, WEB_SMALL_PAYMENT_SOURCE } from "@constants";
import { MODALS } from "@common/Modal/constants";
import Button from "@common/Buttons";
import { showConfetti } from "@utils/common";
import { METHOD_TYPE } from '@containers/SubscriptionPayment/APIs/constants.js';
import { openPopup, closePopup } from "@common/Modal/action";
import FsInstallation from '@containers/SubscriptionPayment/FsInstallation';

import './style.scss';

class PaymentSuccess extends Component {

    componentDidMount = () => {
        let { openPopup, closePopup, paymentStatusVerbiage, onStartClick, showConfettiEffect, isFtvUpsellEnable, isTickTick } = this.props;
        let subscriptionChangeType = getKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE),
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;

        if ((subscriptionChangeType === METHOD_TYPE.ADD_SUBSCRIPTION && showConfettiEffect) || isFtvUpsellEnable()) {
            showConfetti(3 * 1000);
        } else {
            !isTickTick && openPopup(MODALS.ALERT_MODAL, {
                modalClass: `alert-modal error-state-modal plan-success-modal ${get(paymentStatusVerbiage, 'largeScreenAdvertisementImage') && "large-screen-popup-wrapper"}`,
                headingMessage: get(paymentStatusVerbiage, 'header'),
                extraInstructions: get(paymentStatusVerbiage, 'footer'),
                secondaryInstructions: get(paymentStatusVerbiage, 'couponMessage'),
                instructions: get(paymentStatusVerbiage, 'largeScreenAdvertisementImage')
                    ? `<p>${get(paymentStatusVerbiage, 'message') ?? ''}</p>
                    <img src=${get(paymentStatusVerbiage, 'largeScreenAdvertisementImage')} alt='large-screen-advertising-image' />`
                    : get(paymentStatusVerbiage, 'message'),
                primaryButtonText: sourceIsMSales ? "" : 'Start Watching',
                primaryButtonAction: () => {
                    closePopup();
                    onStartClick();
                },
                hideCloseIcon: true,
                icon: true,
                isHtml: true,
            })
        }
    }

    render() {
        let { paymentStatusVerbiage, onStartClick, showConfettiEffect, handleMSalesLogoutJourney, handleDeleteLocalStorageKeys, isFtvUpsellEnable, isTickTick } = this.props;
        let subscriptionChangeType = getKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE),
            sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE;
        return (subscriptionChangeType === METHOD_TYPE.ADD_SUBSCRIPTION && showConfettiEffect) || isFtvUpsellEnable() || isTickTick ?
            <div className={`payment-successful-container ${get(paymentStatusVerbiage, 'largeScreenAdvertisementImage') && "large-screen-advertising-wrapper"}`}>
                <div className={`payment-successful ${isFtvUpsellEnable() ? 'ftv-upsell-block' : ''}`}>
                    <img alt='payment-successful' src={`../../assets/images/Success-tick.png`} />
                    {get(paymentStatusVerbiage, 'header') && <span className='payment-pri-text'>{get(paymentStatusVerbiage, 'header')}</span>}
                    {get(paymentStatusVerbiage, 'footer') && <span className='payment-sec-text'>{get(paymentStatusVerbiage, 'footer')}</span>}
                    {get(paymentStatusVerbiage, 'couponMessage') && <span className='payment-sec-text' dangerouslySetInnerHTML={{ __html: get(paymentStatusVerbiage, 'couponMessage')}}/>}
                    {get(paymentStatusVerbiage, 'message') && <span className='payment-ter-text'>{get(paymentStatusVerbiage, 'message')}</span>}
                    {/* <span className='payment-sec-text'>{get(paymentStatusVerbiage, 'message')}</span>
                    <span className='payment-ter-text'>{get(paymentStatusVerbiage, 'footer')}</span> */}
                    {get(paymentStatusVerbiage, 'largeScreenAdvertisementImage') &&
                        <img
                            src={`${get(paymentStatusVerbiage, 'largeScreenAdvertisementImage')}`}
                            alt="large-screen-advertising-image"
                            className="large-screen-advertising-image"
                        />}
                    {!sourceIsMSales && !isFtvUpsellEnable() && <Button
                        cName={`btn primary-btn payment-success-cta`}
                        bType="button"
                        bValue={'Start Watching'}
                        clickHandler={() => onStartClick()}
                    />}
                </div>
                {isFtvUpsellEnable() &&
                    <FsInstallation sourceIsMSales={sourceIsMSales}
                        onStartClick={onStartClick}
                        handleMSalesLogoutJourney={handleMSalesLogoutJourney}
                        handleDeleteLocalStorageKeys={handleDeleteLocalStorageKeys} />
                }
            </div>
            : null
    }
}


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                closePopup,
                openPopup,
            },
            dispatch,
        ),
    };
};


PaymentSuccess.propTypes = {
    onStartClick: PropTypes.func,
    paymentStatusVerbiage: PropTypes.object,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess),
);
