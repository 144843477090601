import get from "lodash/get";

import { fromLoginLoader, hideMainLoader, showMainLoader, showMainLoaderImmediate } from "@src/action";
import SubscriptionServiceInstance from "./service";
import { ERROR_CODE, LOCALSTORAGE, PARAMS_TYPE, SILENT_LOGIN_PLATFORM, SUBSCRIPTION_TYPE, WEB_SMALL_PAYMENT_SOURCE } from "@constants";
import { delay, handlePackValidationFailed, handleLogoutAllDevices, updateUserInfo, isAtvFsUser } from '@utils/common';

import { ACTION, FORCE_LOGOUT_VERBIAGE, PACK_VALIDATE_DELAY_WAIT_TIME } from './constant';
import store from "@src/store";
import { managedAppPushChanges } from "@components/Header/APIs/actions";
import { deleteKey, getKey, setKey } from "@utils/storage";
import mixPanelConfig from "@utils/mixpanel";
import { isEmpty } from "lodash";


export const getCurrentSubscriptionInfo = (showLoader = false, handleNetworkFailure = false, updatePeopleProperty = false, apiPrimary = false) => {
    return dispatch => {
        showLoader && dispatch(showMainLoader());
        //Added check to call current subscriptin api only if dth status is stored as if dth is not stored and we make a call then this call gies in infinte loop and resulst in 403 error
        const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
        if (!isEmpty(userInfo?.dthStatus)) {
            return SubscriptionServiceInstance.getCurrentSubscriptionInfo(apiPrimary, handleNetworkFailure).then(async (response) => {
                if ([ERROR_CODE.ERROR_100059, ERROR_CODE.ERROR_100048].includes(response?.code)) {
                    let message = response?.code === ERROR_CODE.ERROR_100059 ? response?.data?.logoutDesc : response?.message || FORCE_LOGOUT_VERBIAGE.MESSAGE,
                        title = response?.code === ERROR_CODE.ERROR_100059 ? response?.data?.logoutTitle : response?.title || FORCE_LOGOUT_VERBIAGE.TITLE,
                        buttonVerbiage = response?.code === ERROR_CODE.ERROR_100059 ? response?.data?.logoutButton : 'Ok';
                    handleLogoutAllDevices(title, message, buttonVerbiage)
                    showLoader && dispatch(hideMainLoader());
                }
                else {
                    const params = { type: PARAMS_TYPE.SUBSCRIPTION_DETAILS };
                    updateUserInfo(response, params);
                    showLoader && dispatch(hideMainLoader());
                    dispatch({
                        type: ACTION.GET_CURRENT_SUBSCRIPTION_INFO,
                        apiResponse: response,
                    });
                    !isEmpty(response?.data) && mixPanelConfig.setSuperProperties();
                    mixPanelConfig.setPeopleProperties();
                    !response?.data?.maxCardinalityReached && setKey(LOCALSTORAGE.APP_DROP_POPUP_FREQUENCY, 0);
                }
                return response;
            }).catch((error) => {

                showLoader && dispatch(hideMainLoader());
                dispatch({
                    type: ACTION.GET_CURRENT_SUBSCRIPTION_INFO,
                    apiResponse: error,
                });
                console.log("Error while getting current subscription info :- " + error)
            });
        }
    }
};

export const getPackListing = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.packListing(payload).then((response) => {
            dispatch({
                type: ACTION.PACK_LISTING,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.PACK_LISTING,
                apiResponse: error,
            });
            console.log("Error while fetching pack list :- " + error)
        });
    }
};

export const tenureAccountBal = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.getTenureAccountBal(payload).then((response) => {
            dispatch({
                type: ACTION.GET_ACCOUNT_BALALNCE,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.GET_ACCOUNT_BALALNCE,
                apiResponse: error,
            });
            console.log("Error while fetching tenure account balance :- " + error)
        });
    }
};

export const validateSelectedPack = (payload, requestCount = 1) => {
    return (dispatch, getState) => {
        return SubscriptionServiceInstance.validateSelectedPack(payload).then(async (response) => {
            if (response?.code === ERROR_CODE.ERROR_20022) {
                /** TSF-7470 retry once after waiting for x seconds  */
                if (requestCount > 1) {
                    handlePackValidationFailed(response?.message);
                } else {
                    const { configResponse } = getState().headerDetails;
                    setTimeout(() => dispatch(showMainLoaderImmediate()), 1000); // hack to prevent loader from hiding from apiService
                    await delay(configResponse?.newUserDelay || PACK_VALIDATE_DELAY_WAIT_TIME);
                    return dispatch(validateSelectedPack(payload, requestCount + 1));
                }
            }
            else {
                dispatch({
                    type: ACTION.VALIDATE_SELECTED_PACK,
                    apiResponse: response,
                });
                return response;
            }
        }).catch((error) => {
            dispatch({
                type: ACTION.VALIDATE_SELECTED_PACK,
                apiResponse: error,
            });
            console.log("Error while validating selected pack :- " + error)
        });
    }
};

export const addNewSubscription = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.addNewSubscription(payload).then((response) => {
            dispatch({
                type: ACTION.ADD_NEW_PACK,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.ADD_NEW_PACK,
                apiResponse: error,
            });
            console.log("Error while adding new pack :- " + error)
        });
    }
};

export const modifyExistingSubscription = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.modifyExistingSubscription(payload).then((response) => {
            dispatch({
                type: ACTION.MODIFY_EXISTING_PACK,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.MODIFY_EXISTING_PACK,
                apiResponse: error,
            });
            console.log("Error while modifying existing pack :- " + error)
        });
    }
};

export const cancelSubscription = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.cancelSubscription(payload).then((response) => {
            dispatch({
                type: ACTION.CANCEL_SUBSCRIPTION,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.CANCEL_SUBSCRIPTION,
                apiResponse: error,
            });
            console.log("Error while cancel subscription :- " + error)
        });
    }
};

export const revokeSubscription = () => {
    return dispatch => {
        return SubscriptionServiceInstance.resumePack().then((response) => {
            dispatch({
                type: ACTION.REVOKE_SUBSCRIPTION,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.REVOKE_SUBSCRIPTION,
                apiResponse: error,
            });
            console.log("Error while revoking subscription request:- " + error)
        });
    }
};

export const setUpdatedTenure = (value = {}) => {
    return { type: ACTION.SET_UPDATED_TENURE, value }
};

export const checkCurrentSubscriptionApi = () => {
    return { type: ACTION.CHECK_CALLING_SUBSCRIPTION_API }
}
export const getNotLoggedInPack = () => {
    return dispatch => {
        return SubscriptionServiceInstance.getNotLoggedInPack().then((response) => {
            dispatch({
                type: ACTION.PACK_LISTING,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.PACK_LISTING,
                apiResponse: error,
            });
            console.log("Error while fetching pack list:- " + error)
        });
    }
};

export const getWebPortalLink = (requestHeaders, isCampaign) => {
   !requestHeaders?.couponCode && deleteKey(LOCALSTORAGE.COUPON_DETAILS);
    return dispatch => {
        return SubscriptionServiceInstance.getWebPortalLink(requestHeaders, isCampaign).then((response) => {
            requestHeaders.hasOwnProperty("journeySource") && setKey(LOCALSTORAGE.JOURNEY_SOURCE, requestHeaders?.journeySource);
            requestHeaders.hasOwnProperty("journeySourceRefId") && setKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID, JSON.stringify(requestHeaders?.journeySourceRefId))
            requestHeaders.hasOwnProperty("analyticSource") && setKey(LOCALSTORAGE.SUBCRIPTION_SOURCE, requestHeaders?.analyticSource?.toUpperCase());
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: error,
            });
            console.log("Error while fetching web portal pack list:- " + error);
            return error?.response;
        });
    }
};

export const getWebPortalBackLink = (cartId) => {
    return dispatch => {
        return SubscriptionServiceInstance.getWebPortalBackLink(cartId).then((response) => {
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: error,
            });
            console.log("Error while fetching web portal pack list:- " + error)
        });
    }
};

export const openMiniSubscription = (value) => {
    return { type: ACTION.MINI_SUBSCRIPTION, value }
};

export const clearPackList = () => {
    return { type: ACTION.CLEAR_PACK_LIST }
};

export const getPlanSummaryUrl = (cartId) => {
    return dispatch => {
        dispatch(hideMainLoader())
        return SubscriptionServiceInstance.getPlanSummaryUrl(cartId).then(async (response) => {
            dispatch(hideMainLoader())
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: response,
            });
            return response;

        }).catch(async (error) => {
            dispatch(fromLoginLoader(false))
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: error,
            });
            console.log("Error while fetching web portal pack list:- " + error)
        });
    }
};


export const migrateUserInfo = (migrateUserHeader) => {
    return dispatch => {
        return SubscriptionServiceInstance.migrateUserInfo(migrateUserHeader).then((response) => {
            dispatch({
                type: ACTION.MIGRATE_USER_INFO,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.MIGRATE_USER_INFO,
                apiResponse: error,
            });
            console.log("Error while migrate user info :- " + error)
        });
    }
};


export const getCampaignPageData = () => {
    return dispatch => {
        return SubscriptionServiceInstance.getCampaignPageData().then((response) => {
            dispatch({
                type: ACTION.CAMPAIGN_PAGE_DATA,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.CAMPAIGN_PAGE_DATA,
                apiResponse: error,
            });
            console.log("Error while fetching campaign page data:- " + error)
        });
    }
};

export const getCampaignBannerData = (packName) => {
    return dispatch => {
        return SubscriptionServiceInstance.getCampaignBannerData(packName).then((response) => {
            dispatch({
                type: ACTION.CAMPAIGN_BANNER_DATA,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.CAMPAIGN_BANNER_DATA,
                apiResponse: error,
            });
            console.log("Error while fetching campaign page data:- " + error)
        });
    }
};

export const isUserEligibileForPack = (packId) => {
    return dispatch => {
        return SubscriptionServiceInstance.isUserEligibileForPack(packId).then((response) => {
            dispatch({
                type: ACTION.USER_ELIGIBILE_DATA,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.USER_ELIGIBILE_DATA,
                apiResponse: error,
            });
            console.log("Error while fetching isUserEligibileForPack data:- " + error)
        });
    }
};

export const setRegionalNudgeStatus = () => {
    return { type: ACTION.REGIONAL_NUDGE_STATUS }
};

export const checkFallbackFlow = () => {
    let isMsales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        state = store.getState(),
        fsPlatformList = get(state.headerDetails, "configResponse.data.config.fsPlatform", []),
        silentLoginPlatform = getKey(LOCALSTORAGE.SILENT_LOGIN_PLATFORM) || '',
        isBingeOpenFs = fsPlatformList.includes(silentLoginPlatform),
        isUnlockFsFlow = getKey(LOCALSTORAGE.UNLOCKED_FS_FLOW) || false,
        unlockedOpenFsExist = silentLoginPlatform === SILENT_LOGIN_PLATFORM.BINGE_OPEN_FS && isUnlockFsFlow,
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        isAndroidStickUser = userInfo?.subscriptionType === SUBSCRIPTION_TYPE.ANDROID_STICK;

    return (dispatch) => {
        return SubscriptionServiceInstance.checkFallbackFlow()
            .then((response) => {
                (!!isMsales || isUnlockFsFlow || isAndroidStickUser) ? dispatch(managedAppPushChanges(false)) : dispatch(managedAppPushChanges(response?.data?.enableManageApp))
                return response;
            })
            .catch((error) => {
                (!!isMsales || isUnlockFsFlow) && dispatch(managedAppPushChanges(false));
                console.log("Error while fetching checkFallbackFlow data:- " + error);
            });
    };
};

export const bingeTrialStatus = () => {
    return (dispatch) => {
        return SubscriptionServiceInstance.bingeTrialStatus()
            .then((response) => {
                dispatch({
                    type: ACTION.BINGE_TRIAL_STATUS,
                    apiResponse: response,
                });
                return response;
            })
            .catch((error) => {
                console.log(
                    "Error while fetching isUserEligibileForPack data:- " + error
                );
            });
    };
};

export const getFsPortalLink = (payload) => {
    return dispatch => {
        return SubscriptionServiceInstance.getFsPortalLink(payload).then((response) => {
            response.data = { ...response.data, 'cartId': payload?.cartId }
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.PORTAL_PACK_LINK,
                apiResponse: error,
            });
            console.log("Error while fetching fs upsell portal :- " + error)
        });
    }
};

export const getCurrentSubscriptionMyplanVerbiages = (isDeleteAccount = false) => {
    return dispatch => {
        return SubscriptionServiceInstance.getCurrentSubscriptionMyplanVerbiages(isDeleteAccount).then((response) => {
            isDeleteAccount ? dispatch({
                type: ACTION.HELP_CENTER_DELETE_ACCOUNT_VERBIAGE,
                apiResponse: response,
            }) :
                dispatch({
                    type: ACTION.CURRENT_SUBCRIPTION_MY_PLAN_VERBIAGES,
                    apiResponse: response,
                });
            return response;
        }).catch((error) => {
            isDeleteAccount ? dispatch({
                type: ACTION.HELP_CENTER_DELETE_ACCOUNT_VERBIAGE,
                apiResponse: error,
            }) :
                dispatch({
                    type: ACTION.CURRENT_SUBCRIPTION_MY_PLAN_VERBIAGES,
                    apiResponse: error,
                });
            console.log("Error while fetching verbiages API response:- " + error)
        });
    }
};

export const getPrimeEntitlementDetails = () => {
    return dispatch => {
        return SubscriptionServiceInstance.getPrimeEntitlementDetails().then((response) => {
            dispatch({
                type: ACTION.GET_PRIME_ENTITLEMENT_DETAILS,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.GET_PRIME_ENTITLEMENT_DETAILS,
                apiResponse: error,
            });
            console.log("Error while fetching current prime entitlement details :- " + error)
        });
    }
};

export const fetchPrimeExistingSubscriptionWatchFlag = () => {
    return dispatch => {
        return SubscriptionServiceInstance.fetchPrimeExistingSubscriptionWatchFlag().then((response) => {
            dispatch({
                type: ACTION.FETCH_PRIME_EXISTING_SUBSCRIPTION_FLAG,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.FETCH_PRIME_EXISTING_SUBSCRIPTION_FLAG,
                apiResponse: error,
            });
            console.log("Error while fetching watch with existing prime subscription flag :- " + error)
        });
    }
};

export const postPubnubSyncBEAndLS = (source) => {
    return dispatch => {
        return SubscriptionServiceInstance.postPubnubSyncBEAndLS(source).then((response) => {
            dispatch({
                type: ACTION.POST_DETAILS_FOR_PUNUB_SYNC,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.POST_DETAILS_FOR_PUNUB_SYNC,
                apiResponse: error,
            });
            console.log("Error while posting details for pubnub sync BE and LS:- " + error)
        });
    }
};

export const setRenewNudgeStatus = (value) => {
    return { type: ACTION.RENEW_NUDGE_STATUS, value }
};

export const getPartnerStatus = () => {
    return dispatch => {
        return SubscriptionServiceInstance.getPartnerStatus().then((response) => {
            dispatch({
                type: ACTION.GET_APPLE_PRIME_STATUS,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.GET_APPLE_PRIME_STATUS,
                apiResponse: error,
            });
            console.log("Error while fetching the status :- " + error)
        });
    }
};


export const setAppleRenewnudge = (value) => {
    return { type: ACTION.APPLE_ACTIVATE_RENEW_NUDGE , value}
};

export const validateSubscriptionCoupon = (payload) => {
    return dispatch => {
        dispatch(showMainLoader());
        return SubscriptionServiceInstance.validateSubscriptionCoupon(payload).then((response) => {
            dispatch(hideMainLoader());
            dispatch({
                type: ACTION.VALIDATE_SUBSCRIPTION_COUPON,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.VALIDATE_SUBSCRIPTION_COUPON,
                apiResponse: error,
            });
            console.log("Error while validating subscription coupon :- " + error)
        });
    }
};
export const guestUserValidateCoupon = (coupon) => {
    return dispatch => {
        dispatch(showMainLoaderImmediate())
        return SubscriptionServiceInstance.guestUserValidateCoupon(coupon).then((response) => {
            dispatch(hideMainLoader());
            dispatch({
                type: ACTION.GUEST_USER_VALIDATE_COUPON,
                apiResponse: response,
            });
            
            return response;
        }).catch((error) => {
            dispatch({
                type: ACTION.GUEST_USER_VALIDATE_COUPON,
                apiResponse: error,
            });
            console.log("Error while validating coupon :- " + error)
        });
    }
};