import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import get from "lodash/get";
import Slider from "react-slick";

import { URL } from "@constants/routeConstants";
import {
    cloudinaryCarousalUrl,
    isUserloggedIn,
    safeNavigation,
    isMobile,
    isIpadWebView,
} from "@utils/common";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import moengageConfig from "@utils/moengage";
import MOENGAGE from "@constants/moengage";

import { helpCenterPopularityTrack } from "../../APIs/action";

import RightArrow from "@assets/images/arrow-right.svg";
import LeftArrow from "@assets/images/left-arrow.svg";
import placeHolderImage from "@assets/images/video-placeholder.png";

import "./style.scss";
import "../../style.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class HelpVideoCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentslide: 0,
        };
    }

    getYouTubeImageThumbnail = (thumbnailImageUrl) => {
        let height = 300,
            width = 170;
        if (thumbnailImageUrl) {
            return `${cloudinaryCarousalUrl(
                "",
                "",
                height,
                width
            )}${thumbnailImageUrl}`;
        } else {
            return placeHolderImage;
        }
    };

    getYouTubeTime = (time) => {
        let displayTime = "";
        if (time && time > 0) {
            time < 60
                ? (displayTime = `${time}s`)
                : (displayTime = `${(time / 60).toFixed(2)}m`);
        }
        return displayTime;
    };

    handleVideoOnClick = (item, isUrlLink = false) => {
        let redirectionUrl = `/${URL.HELP_CENTER}/${URL.HC_VIDEO_DETAIL}/${item.id}/${item.type}`
        if(isUrlLink){
            return redirectionUrl
        }
        this.trackAnalytics(item?.title);
        if (isUserloggedIn()) {
             this.props.helpCenterPopularityTrack(item).then(() => {
                safeNavigation(this.props.history, {
                    pathname: redirectionUrl,
                });
            });
        } else {
            safeNavigation(this.props.history, {
                pathname: redirectionUrl,
            });
        }
    };

    handleSeeAllVideoClick = (value, isUrlLink = false) => {
        let {
            location: { pathname },
            seeAllUrl,
            handleViewMoreClick,
        } = this.props;
        const urlArr = pathname.split("/");

        let redirectUrl = `/${URL.HELP_CENTER}/${URL.HC_VIDEO_SEE_ALL}`
        if(isUrlLink){
            return redirectUrl
        }

        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_VIEW_ALL_VIDEOS, {
            [MIXPANEL.PARAMETER.SOURCE]: value
                ? MIXPANEL.PARAMETER.CHEVRON_ICON
                : MIXPANEL.PARAMETER.VIEW_ALL_VIDEOS_CARD,
        });

        if (urlArr.includes(URL.HC_VIDEO_SEE_ALL)) {
            handleViewMoreClick();
        } else {
            safeNavigation(this.props.history, {
                pathname: redirectUrl,
                state: {
                    seeAllUrl,
                },
            });
        }
    };

    VideoClickData = (analytics, title) => {
        return {
            [`${analytics.PARAMETER.SOURCE}`]: this.props.screenName,
            [`${analytics.PARAMETER.TITLE}`]: title,
        };
    };

    trackAnalytics = (title) => {
        mixPanelConfig.trackEvent(
            MIXPANEL.EVENT.HELP_VIDEO_CLICK,
            this.VideoClickData(MIXPANEL, title)
        );
        moengageConfig.trackEvent(
            MOENGAGE.EVENT.HELP_VIDEO_CLICK,
            this.VideoClickData(MOENGAGE, title)
        );
    };

    getHelpVideoCardItem = (item, index) => {
        let {
            title,
            isLandingPage,
            viewAllBtnText = "View All Videos",
        } = this.props;

        if (item?.type === "VIEW_ALL_CTA") {
            return (
                <li
                    key={index}
                    className="helpvideo-item-wrapper view-all-card-btn"
                >
                    <Link
                        to = {this.handleSeeAllVideoClick('', true)}
                        className="view-all"
                        onClick={(e) =>(
                            e.preventDefault(),
                            this.handleSeeAllVideoClick()
                        )}
                    >
                        {viewAllBtnText}
                    </Link>
                </li>
            );
        } else {
            return (
                <li
                    key={index}
                    className="helpvideo-item-wrapper">
                    <Link to={this.handleVideoOnClick(item, true)}
                        onClick={(e) => (
                            e.preventDefault(),
                            this.handleVideoOnClick(item))}>
                        <div className="img-contr">
                            <img
                                src={this.getYouTubeImageThumbnail(item?.thumbnailImageUrl)}
                                alt=""
                                title={title}
                                className="img-fluid"
                            />
                            <div className="video-icon">
                                <span
                                    className="play-icon"
                                    title="Play Video"
                                ></span>
                            </div>
                        </div>
                        <div className="text-contr">
                            <span className="text">
                                {item.title}
                            </span>
                            {!isLandingPage && <span className="time-duration">{this.getYouTubeTime(item?.duration)}</span>}
                        </div>
                    </Link>
                </li>
            );
        }
    }

    getBottomCTA = () => {
        let {
            showViewAllSection = false,
            viewAllBtnText = "View All Videos",
            isLandingPage,
            hcSeeAllData,
            hcSeeAllDataItems,
            handleResetVideoData
        } = this.props;
        let checkViewAllSection = hcSeeAllData?.totalCount === hcSeeAllDataItems?.length;

        if (showViewAllSection && !isLandingPage) {
            return (<div className="text-center vdo-btn-contr">
                <a className="btn btn-tertiary waves-effect waves-light"
                    onClick={this.handleSeeAllVideoClick}>
                    {viewAllBtnText}
                </a>
            </div>);
        }
        else if (checkViewAllSection && hcSeeAllDataItems?.length > 8) {
            return (<div className="text-center vdo-btn-contr">
                <a className="btn btn-tertiary waves-effect waves-light"
                    onClick={handleResetVideoData}
                >
                    View Less
                </a>
            </div>);
        }
    }

    slidesToShow = () => {
        let slidesNumber;
        let appWidth = document.getElementById('app').clientWidth;
        slidesNumber = appWidth / 249;
        return Math.floor(slidesNumber);
    };

    isInViewport() {
        let element = document.getElementsByClassName("view-all-card-btn");
        if (element.length == 0) {
            return false;
        }
        const rect = element[0]?.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    render() {
        let {
            helpVideoList,
            title,
            wrapperClassName,
            isLandingPage,
            isHelpVideoSeeall,
        } = this.props;

        let updatedHelpVideoList = [...helpVideoList];

        if (isLandingPage && helpVideoList.length > 4) {
            updatedHelpVideoList = [...helpVideoList, {
                type: "VIEW_ALL_CTA",
                id: 'buttonType'
            }];
        }


        let clientWidth = document.getElementById('app').clientWidth;
        let isTabletPortraitView = clientWidth > 480 && clientWidth < 996;
        const sliderSettings = {
            arrows: true,
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: isTabletPortraitView ? this.slidesToShow() : 4,
            slidesToScroll: 1,
            afterChange: (index) => {
                this.setState({ currentslide: isTabletPortraitView ? index + 2 : index + 4 });

            },
            prevArrow:
                this.state.currentslide !== 0 ? (
                    <img
                        className="slick-prev"
                        src={LeftArrow}
                        style={{ width: "24px", height: "24px" }}
                    />
                ) : (
                    <></>
                ),
            nextArrow:
                (isTabletPortraitView ? !this.isInViewport() : this.state.currentslide !== updatedHelpVideoList.length) ? (
                    <img
                        className="slick-next"
                        src={RightArrow}
                        style={{ width: "24px", height: "24px" }}
                    />
                ) : (
                    <></>
                ),
        }

        return (
            <section
                className={`help-video-card-section altBg video-wrapper ${wrapperClassName}`}
            >
                <div className="container">
                    {title && (
                        <Link
                            to={this.handleSeeAllVideoClick(title,true)}
                            onClick={(e) => (
                                e.preventDefault(),
                                this.handleSeeAllVideoClick(title))}
                            className="heading-md"
                        >
                            <h2 className="heading1">{title}</h2>
                            {isLandingPage && updatedHelpVideoList.length > 4 && (
                                <div className="arrow-icon">
                                    <img src={RightArrow} width="8" />
                                </div>
                            )}
                        </Link>
                    )}
                    <div className="body-container">
                        {(!isMobile.any() || isIpadWebView()) && isLandingPage && updatedHelpVideoList.length > 4 ? (
                            <ul
                                className={`video-contr ${isLandingPage ? "" : "landing-page"}`}
                            >
                                <Slider {...sliderSettings}>
                                    {(updatedHelpVideoList || []).map((item, index) => this.getHelpVideoCardItem(item, index))}
                                </Slider>
                            </ul>
                        ) : (
                            <ul className={`video-contr ${isHelpVideoSeeall ? "see-all-page" : "landing-page"} ${updatedHelpVideoList.length === 4 && 'help-video-rail'}`} >
                                {(updatedHelpVideoList || []).map((item, index) => this.getHelpVideoCardItem(item, index))}
                            </ul>
                        )}
                    </div>
                    {this.getBottomCTA()}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    hCPopularityResp: get(state.helpCenterReducer, "hCPopularityResp"),
    hcSeeAllData: get(state.helpCenterReducer, "hcSeeAllData"),
    hcSeeAllDataItems: get(state.helpCenterReducer, "hcSeeAllDataItems"),
});

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                helpCenterPopularityTrack,
            },
            dispatch
        ),
    };
}

HelpVideoCard.propTypes = {
    helpVideoList: PropTypes.array,
    title: PropTypes.string,
    wrapperClassName: PropTypes.string,
    showViewAllSection: PropTypes.bool,
    viewAllBtnText: PropTypes.string,
    getHCViewMoredata: PropTypes.func,
    seeAllUrl: PropTypes.string,
    helpCenterPopularityTrack: PropTypes.func,
    hCPopularityResp: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    handleViewMoreClick: PropTypes.func,
    screenName: PropTypes.string,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(HelpVideoCard);
