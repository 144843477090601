import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router";

import { REGEX, DTH_TYPE, ERROR_CODE, MINI_SUBSCRIPTION, LOCALSTORAGE, VALIDATE_SOURCE } from "@utils/constants";
import { closePopup, openPopup } from "@common/Modal/action";
import { MESSAGE } from "@constants";
import { MODALS } from "@common/Modal/constants";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import {
    addNewSubscription,
    modifyExistingSubscription,
    validateSelectedPack,
    openMiniSubscription,
    getCurrentSubscriptionInfo,
} from '@containers/Subscription/APIs/action';
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@utils/constants/appsFlyer";
import { hideMainLoader, togglePaginationLoaderVisbility, fromLoginLoader } from "@src/action";
import { getKey, setKey } from "@utils/storage";
import { rmnMaskingFunction } from "@containers/BingeLogin/bingeLoginCommon";
import googleConversionConfig from "@utils/googleCoversion";
import googleConversion from "@utils/constants/googleConversion";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import { INTEGRATED_PARTNER_LIST } from "@utils/constants/playerConstant";
import { cloudinaryCarousalUrl, getAllIntegratedPartnerList, safeNavigation } from '@utils/common';
import { setLiveUserLogin } from "@containers/PIDetail/API/actions";

import trackEvent from "../../utils/trackEvent";
import {
    generateOtpWithRMN,
    subscriberListing,
    updateLoginStep,
    closeLoginPopup,
    resetLoginState,
    openLoginPopup,
    setLoginManual,
    setManualLoginSource,
    setIsLoginPopupVisible,
} from './APIs/actions';
import OTPComponent from './OTPComponent';
import LoginSliderRail from './LoginSliderRail';
import RMNComponent from './RMNComponent';
import MultipleBingeSubscriptions from "./MultipleBingeSubscriptions";
import loginLogo from "../../assets/images/bingeLogo.svg";
import { updateUser, createUser, getFireBaseData, onLoginSuccess, isDetailPage, getQRCodeJourneyMixpanelProperties, resetQrCodeData } from "./LoginCommon";

import "./style.scss";

import backArrow from "@assets/images/back-arrow.png";

import "./style.scss";
import { URL } from "@utils/constants/routeConstants";
import store from "@src/store";

class Login extends Component {
    constructor(props) {
        super(props);
        this.sourceArray = this.props?.miniSubscription?.validateJourneySource || this.props?.validateJourneySource;
        this.isSourceValidation = [VALIDATE_SOURCE.COUPON_REDEEM, VALIDATE_SOURCE.DELETE_ACCOUNT].includes(this.sourceArray);
        this.qrCodeMixpanelProperties = getQRCodeJourneyMixpanelProperties();

        this.state = {
            rmn: this.isSourceValidation ? this.props?.rmnValue || this.props?.miniSubscription?.rmnValue : "",
            otp: "",
            selectedSubscriber: "",
            subscriptionsList: [],
            getOtpIsDisabled: true,
            stepNumber: this.isSourceValidation ? 2 : 1,
            rmnError: "",
            loaderVisible: false
        };
    }

    componentDidMount() {
        this.props.setIsLoginPopupVisible(true);
        this.props.resetLoginState();
        this.props.togglePaginationLoaderVisbility(false);
        !this.isSourceValidation && this.trackAnalytics();
        // // handling of login flag for LIVE user from any source viz - play button, hamburger
        // let urlArr = this.props.history?.location?.pathname.split("/"),
        // isLoginFromLivePiPage = isDetailPage(this.props.history) && ['live'].includes(urlArr[2]);
        // isLoginFromLivePiPage && this.props.setLiveUserLogin(true)
        this.sourceArray?.includes(VALIDATE_SOURCE.DELETE_ACCOUNT) && this.hanldeHcDeleteAccount();
        this.props.setManualLoginSource(this.props.source); // Set login source in reducer and use in mixpanel events
        let removeQRCodeData  = this.props.qrCodeId && this.props.source !== MIXPANEL.VALUE.QR_CODE;
        removeQRCodeData && resetQrCodeData();
    }

    componentWillUnmount() {
        this.props.setIsLoginPopupVisible(false)
    }

    hanldeHcDeleteAccount = async () => {
        this.updateStepNumber(2)
        await this.props.generateOtpWithRMN(this.state?.rmn, true);
    };

    trackAnalytics = () => {
        let { ComponentName, source, fireBaseSource } = this.props;
        if (ComponentName === MINI_SUBSCRIPTION.LOGIN) {
            let mixpanel = { 
                [`${MIXPANEL.PARAMETER.SOURCE}`]: source || '',
                ...this.qrCodeMixpanelProperties, 
            }
            trackEvent.loginEnter(fireBaseSource || source);
            source === MIXPANEL.VALUE.DISCOUNTING_PAGE && (mixpanel = { ...mixpanel, ...{ [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: source || '', } })
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_PAGE_VISIT, mixpanel);
        };
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.VIEW_LOGIN, {
            [APPSFLYER.PARAMETER.SOURCE]: this.props.source || "",
        });
    };

    handleChange = (name, value) => {
        if (!isEmpty(this.state.rmnError)) {
            this.setState({
                rmnError: "",
            });
        }
        this.setState({
            [name]: value,
        });
        this.validateChange(name, value);
    };

    validateChange = (name, value) => {
        this.setState({
            getOtpIsDisabled: !REGEX.MOBILE_NUMBER.test(value),
        });
    };

    handleLoginClose = () => {
        const { closePopup, showLoginPopup, closeLoginPopup, openMiniSubscription, location } = this.props;
        closePopup();
        openMiniSubscription()
        if (showLoginPopup) {
            closeLoginPopup();
            this.updateStepNumber(1);
        }
        else if (location?.state?.isFromPi) {
            setKey(LOCALSTORAGE.PI_DETAIL_URL, location.state.url);
            setKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI, `true`)
        }
    };

    radioButtonClickHandler = (name, itemDetails, index) => {
        this.setState({
            [name]: { ...itemDetails, ...{ selectedItemIndex: index } },
        });
    };

    handleGetOtpClick = async (resendOtpClick = false) => {
        const { generateOtpWithRMN, selectedPlan, source, fireBaseSource } = this.props;
        const { rmn, otp, stepNumber } = this.state;
        this.setState({
            getOtpIsDisabled: true,
        });
        await generateOtpWithRMN(rmn);
        !resendOtpClick && mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_GET_OTP, {
            [MIXPANEL.VALUE.RMN]: rmn,
            [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
            ...this.qrCodeMixpanelProperties,
        })
        if (selectedPlan) {
            dataLayerConfig.trackEvent(DATALAYER.EVENT.SUB_JOURNEY,
                {
                    [DATALAYER.PARAMETER.BUTTON_NAME]: DATALAYER.VALUE.GET_OTP,
                    [DATALAYER.PARAMETER.PACK_NAME]: selectedPlan?.productName,
                    [DATALAYER.PARAMETER.PACK_PRICE]: selectedPlan?.amountValue,
                }
            )
        } else {
            dataLayerConfig.trackEvent(DATALAYER.EVENT.GET_OTP_LOGIN_JOURNEY, {})
        }

        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.LOGIN_OTP_REQUESTED, {
            [APPSFLYER.PARAMETER.TYPE]: APPSFLYER.VALUE.RMN,
            [APPSFLYER.PARAMETER.AUTH]: APPSFLYER.VALUE.OTP,
            [APPSFLYER.PARAMETER.VALUE]: rmnMaskingFunction(rmn),
            [APPSFLYER.PARAMETER.SOURCE]: this.props.source || "",
        });

        const { rmnResponse } = this.props;
        if (rmnResponse && rmnResponse.code === 0) {
            this.updateStepNumber(2);
            // !resendOtpClick && mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_GET_OTP, {
            //     [`${MIXPANEL.VALUE.RMN}`]: rmn,
            // })
            stepNumber === 1 && mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_RMN_ENTER, {
                [MIXPANEL.VALUE.RMN]: rmn,
                [MIXPANEL.PARAMETER.SOURCE]: this.props.source || '',
                ...this.qrCodeMixpanelProperties
            })
            googleConversionConfig.trackEvent(googleConversion.EVENT.LOGIN_GET_OTP)
            trackEvent.loginOTPInvoke(getFireBaseData({ rmn, otp, source: fireBaseSource || source }))
            this.setState({
                getOtpIsDisabled: false,
            });
        } else {
            // if (rmnResponse && rmnResponse.code === 20090) {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_RMN_ENTER_INVALID, {
                [MIXPANEL.PARAMETER.DEVICE]: MIXPANEL.VALUE.WEB,
                [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
            });
            // }
            this.setState({
                rmnError: rmnResponse?.message
                    ? rmnResponse.message
                    : "Invalid Mobile Number",
                getOtpIsDisabled: false,
            });
        }
    };

    updateStepNumber = (stepNumber) => {
        this.setState({ stepNumber });
        this.props.updateLoginStep(stepNumber);
    };

    onOtpVerification = (otp) => {
        this.setState({
            otp,
        }, () => {
            if (this.sourceArray?.includes(VALIDATE_SOURCE.DELETE_ACCOUNT)) {
                mixPanelConfig.trackEvent(MIXPANEL.EVENT.DELETE_ACCOUNT_OTP_VALIDATED, '');
                safeNavigation(this.props.history, `/${URL.HELP_CENTER}/${URL.HC_CONFIRM_DELETE}`);
                return;
            }
            else {
                this.props.isPaginationLoaderVisible && this.props.togglePaginationLoaderVisbility(false) 
                this.getSubscriptionDetails();
            }

        }
        );
    };

    getSubscriptionDetails = () => {
        this.props
            .subscriberListing(get(this.state, "rmn"))
            .then((subscriptionDetails) => {
                if (subscriptionDetails && subscriptionDetails.code === 0) {
                    this.setState(
                        {
                            subscriptionsList: get(subscriptionDetails, "data.accountDetails"),
                        },
                        () => this.checkSubscriptionStatus()
                    );
                } else {
                    subscriptionDetails?.response?.status !== ERROR_CODE.ERROR_500 &&
                        this.errorMessage(subscriptionDetails?.message);
                }
            });
    };

    checkSubscriptionStatus = async () => {
        const { subscriptionsList, rmn } = this.state;

        if (isEmpty(subscriptionsList)) {
            await this.createNewUser(rmn, DTH_TYPE.NON_DTH_USER); // this case will come for Non- DTH users only
        } else if (subscriptionsList.length === 1) {
            this.setState(
                {
                    selectedSubscriber: subscriptionsList[0],
                },
                () => this.checkAccountStatus()
            );
        } else if (subscriptionsList.length > 1) {
            this.updateStepNumber(3);
            this.trackMixpanelEvent(MIXPANEL.EVENT.LOGIN_BINGEID_PAGE_VISIT);
        }
    };

    trackMixpanelEvent = (eventName) => {
        const { selectedSubscriber } = this.state;
        let subscriberSource = getKey(LOCALSTORAGE.SUBCRIPTION_SOURCE);
        let subscriptionSourcePlayback = subscriberSource?.toLowerCase() === MIXPANEL.VALUE.CONTENT_PLAYBACK
            || this.props?.source === MIXPANEL.VALUE.CONTENT_PLAYBACK;
        let isComingFromManageApp = [MIXPANEL.VALUE.SUBSCRIPTIONPAGE_EXISTINGUSER, MIXPANEL.VALUE.SUBSCRIPTIONPAGE_PLANSELECTION].includes(this.props.source);
        let source = subscriptionSourcePlayback && !isComingFromManageApp ? MIXPANEL.VALUE.PLAYBACK : this.props?.source;
        let mixpanelData = {
            [MIXPANEL.PARAMETER.SOURCE]: source,
            ...this.qrCodeMixpanelProperties,
        }
        let aliasName = selectedSubscriber?.aliasName?.includes("Linked to") ? selectedSubscriber?.aliasName.split(' ').slice(2).join(' ') : selectedSubscriber?.aliasName;

        if (eventName === MIXPANEL.EVENT.LOGIN_BINGEID_PAGE_PROCEED) {
            mixpanelData = {
                ...mixpanelData,
                [MIXPANEL.PARAMETER.BINGE_ID]: get(selectedSubscriber, 'bingeSubscriberId'),
                [MIXPANEL.PARAMETER.PACK_NAME]: get(selectedSubscriber, 'subscriptionProfileName'),
                [MIXPANEL.PARAMETER.PACK_STATUS]: get(selectedSubscriber, 'statusType'),
                [MIXPANEL.PARAMETER.ALIAS_NAME]: aliasName,
                [MIXPANEL.PARAMETER.SUB_ID]: get(selectedSubscriber, 'subscriberId'),
            }
        }

        mixPanelConfig.trackEvent(eventName, mixpanelData);
    }

    checkAccountStatus = async () => {
        const { selectedSubscriber, subscriptionsList } = this.state;
        const accountId = get(selectedSubscriber, "baId");
        const subscriberId = get(selectedSubscriber, "subscriberId");
        subscriptionsList.length > 1 && this.trackMixpanelEvent(MIXPANEL.EVENT.LOGIN_BINGEID_PAGE_PROCEED);

        // if (!accountId) {
        //     await this.createNewUser(
        //         subscriberId,
        //         get(selectedSubscriber, "dthStatus")
        //     );
        // } else {
        this.checkDTHStatus()
        // }
    };

    checkDTHStatus = async () => {
        const { selectedSubscriber } = this.state;
        const dthStatus = get(selectedSubscriber, "dthStatus");
        if (dthStatus === DTH_TYPE.DTH_WO_BINGE_USER) {
            await this.createNewUser(
                get(selectedSubscriber, "subscriberId"),
                dthStatus,
                get(selectedSubscriber, "baId")
            );
        } else {
            this.updateExistingUser();
        }
        // mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_SUBSCRIPTIONID_SELECT, { [MIXPANEL.PARAMETER.SUBSCRIPTION_ID]: selectedSubscriber?.bingeSubscriberId || selectedSubscriber?.subscriptionId });
    };

    updateExistingUser = async () => {
        const { selectedSubscriber, rmn, otp } = this.state;
        const { updatedTenure, history, source, selectedPlan, cartId, loginBeforeSubscribeSource, contentData, handleSubscription, couponCode  } = this.props
        const data = {
            baId: get(selectedSubscriber, "baId"),
            bingeSubscriberId: get(selectedSubscriber, "bingeSubscriberId"),
            dthStatus: get(selectedSubscriber, "dthStatus"),
            subscriberId: get(selectedSubscriber, "subscriberId"),
            loginErrorMessage: get(selectedSubscriber, "loginErrorMessage"),
            isPastBingeUser: get(selectedSubscriber, "isPastBingeUser"),
            packageId: updatedTenure?.tenureId || "",
            rmn,
            otp,
            cartId: cartId,
            loginBeforeSubscribeSource: loginBeforeSubscribeSource,
            contentData: contentData,
            handleSubscription: handleSubscription,
            couponCode: couponCode,
        };
        await updateUser(data, onLoginSuccess, history, source, this.handleLoginClose, selectedPlan, cartId);
    };

    createNewUser = async (subscriberId, dthStatus, baId) => {
        const { selectedSubscriber, rmn, otp } = this.state;
        const { updatedTenure, history, source, selectedPlan, cartId, loginBeforeSubscribeSource, contentData, handleSubscription, couponCode } = this.props;
        const referenceId = get(selectedSubscriber, "referenceId");
        const isDthWithoutBinge = (dthStatus === DTH_TYPE.DTH_WO_BINGE_USER);
        const data = {
            subscriberId,
            dthStatus,
            otp,
            rmn,
            baId,
            loginErrorMessage: get(selectedSubscriber, "loginErrorMessage"),
            isPastBingeUser: get(selectedSubscriber, "isPastBingeUser", false),
            eulaChecked: true,
            packageId: updatedTenure?.tenureId || "",
            cartId: cartId,
            loginBeforeSubscribeSource: loginBeforeSubscribeSource,
            contentData: contentData,
            handleSubscription: handleSubscription
        };
        if (isDthWithoutBinge) {
            data['referenceId'] = referenceId
        }
        await createUser(data, onLoginSuccess, history, source, this.handleLoginClose, selectedPlan, cartId, couponCode);
    };

    errorMessage = (message) => {
        this.props.openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal",
            instructions: message || MESSAGE.ERROR_OCCURRED,
            primaryButtonText: "Ok",
            closeModal: true,
            hideCloseIcon: true,
        });
    };

    handleBack = async () => {
        if (this.isSourceValidation) {
            this.props.closePopup();
            this.props.openMiniSubscription()
        }
        this.updateStepNumber(1);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_OTP_PAGE_BACK, {
            [MIXPANEL.VALUE.RMN]: this.state.rmn,
            [MIXPANEL.PARAMETER.SOURCE]: this.props.source,
        });
    }

    getIntegratedPartnersList = () => {
        let ALL_INTEGRATED_PARTNER_LIST = getAllIntegratedPartnerList();
        return get(this.props?.configResponse, "data.config.providers", []).filter(provider => ALL_INTEGRATED_PARTNER_LIST.includes(provider?.name?.toLowerCase()));
    };

    render() {
        const {
            rmn,
            getOtpIsDisabled,
            stepNumber,
            rmnError,
            subscriptionsList,
            selectedSubscriber,
        } = this.state;
        const {
            touchStatus,
            barStatus,
            configResponse,
            openPopup,
            closePopup,
            openLoginPopup,
            showNotNowPopup,
            source,
            validateJourneySource
        } = this.props;
        const showContent = [1, 2].includes(stepNumber);
        const clientWidth = document.getElementById("app").clientWidth;
        const showHeaderLogo = clientWidth < 480 || showContent || stepNumber === 3;
        const showLogo = get(configResponse, "data.config.loginScreen.logo")
        let providers = this.getIntegratedPartnersList();
        const state = store.getState();
        const deleteAccountLoginVerbiages = get(state?.helpCenterReducer?.hcDeletAccountVerbiages, "deleteAccountConfirmationValidationDTO");
        let loginTitle = this.isSourceValidation ? get(deleteAccountLoginVerbiages, "enterOtpHeader") : get(configResponse, "data.config.loginScreen.login");
        let isSourceCoupnRedeem = validateJourneySource === VALIDATE_SOURCE.COUPON_REDEEM;

        return (
            <React.Fragment>
                <div className={`login-details-container ${isSourceCoupnRedeem ? 'copon-redem-login' : ''}`}>
                    {(stepNumber === 2) && <div className="back-arrow" onClick={this.handleBack}><img src={backArrow} /></div>}
                    <React.Fragment>
                        {showHeaderLogo && (
                            <div className="header-logo">
                                {showLogo ?
                                    <img src={`${cloudinaryCarousalUrl("", "", 0, 0, false, true)}${(showLogo)}`} alt="tata-sky-logo" />
                                    :
                                    <img src={loginLogo} alt="tata-sky-logo" />
                                }
                            </div>
                        )}
                        {showContent && (
                            <>
                                <div className="login-title-web">
                                    <p>
                                        {loginTitle}
                                    </p>
                                </div>
                                <LoginSliderRail
                                    railItems={providers}
                                />
                                <div className="login-title-small">
                                    <p>
                                        {loginTitle}
                                    </p>
                                </div>
                            </>
                        )}
                    </React.Fragment>
                    {stepNumber === 1 && (
                        <RMNComponent
                            rmn={rmn}
                            getOtpIsDisabled={getOtpIsDisabled}
                            handleChange={this.handleChange}
                            handleGetOtpClick={this.handleGetOtpClick}
                            closeLoginModel={this.handleLoginClose}
                            rmnError={rmnError}
                            configResponse={this.props.configResponse}
                            openPopup={openPopup}
                            closePopup={closePopup}
                            openLoginPopup={openLoginPopup}
                            showNotNowPopup={showNotNowPopup}
                            isFromCampaign={this.props.isFromCampaign}
                            history={this.props.history}
                            source={this.props.source}
                        />
                    )}
                    {stepNumber === 2 && (
                        <OTPComponent
                            rmn={rmn}
                            onOtpVerification={this.onOtpVerification}
                            handleResendOtp={this.handleGetOtpClick}
                            selectedPlan={this.props.selectedPlan}
                            source={this.props.source}
                            otpValidateSource={this.props?.validateJourneySource || this.props?.miniSubscription?.validateJourneySource}
                            qrCodeMixpanelProperties={this.qrCodeMixpanelProperties}
                        />
                    )}
                    {stepNumber === 3 && <MultipleBingeSubscriptions
                        radioButtonClickHandler={this.radioButtonClickHandler}
                        subscriptionsList={subscriptionsList}
                        selectedSubscriber={selectedSubscriber}
                        handleProceedBtn={this.checkAccountStatus}
                        closeLoginModel={this.handleLoginClose}
                        touchStatus={touchStatus}
                        barStatus={barStatus}
                        openPopup={openPopup}
                        closePopup={closePopup}
                        configResponse={this.props.configResponse}
                        openLoginPopup={openLoginPopup}
                        source={this.props.source}
                    />}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        subscriptionDetails: get(state.loginReducer, "subscriptionDetails"),
        configResponse: get(state.headerDetails, "configResponse"),
        showLoginPopup: get(state.loginReducer, "showLoginPopup"),
        rmnResponse: get(state.loginReducer, "rmnResponse"),
        existingUser: get(state.loginReducer, "existingUser"),
        newUser: get(state.loginReducer, "newUser"),
        fromLogin: get(state.commonContent, "fromLogin"),
        tenureAccountBalance: get(state.subscriptionDetails, 'tenureAccountBal'),
        validateSelectedPackResp: get(state.subscriptionDetails, 'validateSelectedPackResp'),
        currentSubscription: get(state.subscriptionDetails, 'currentSubscription.data'),
        updatedTenure: get(state.subscriptionDetails, 'selectedTenureValue'),
        miniSubscription: get(state.subscriptionDetails, 'miniSubscription'),
        isPaginationLoaderVisible: get(state.commonContent, "isPaginationLoaderVisible"),
        qrCodeId: get(state.qrLoginReducer, 'qrCodeId')
    };
};

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({
            generateOtpWithRMN,
            subscriberListing,
            updateLoginStep,
            closePopup,
            closeLoginPopup,
            openPopup,
            resetLoginState,
            openLoginPopup,
            setLoginManual,
            togglePaginationLoaderVisbility,
            getCurrentSubscriptionInfo,
            validateSelectedPack,
            addNewSubscription,
            modifyExistingSubscription,
            hideMainLoader,
            setManualLoginSource,
            fromLoginLoader,
            openMiniSubscription,
            setIsLoginPopupVisible,
            setLiveUserLogin,
        }, dispatch),
    }
}

Login.propTypes = {
    generateOtpWithRMN: PropTypes.func,
    subscriberListing: PropTypes.func,
    closePopup: PropTypes.func,
    bottomSheetClose: PropTypes.func,
    updateLoginStep: PropTypes.func,
    closeLoginPopup: PropTypes.func,
    subscriptionDetails: PropTypes.object,
    barStatus: PropTypes.bool,
    touchStatus: PropTypes.bool,
    showLoginPopup: PropTypes.bool,
    configResponse: PropTypes.object,
    openPopup: PropTypes.func,
    rmnResponse: PropTypes.object,
    existingUser: PropTypes.object,
    newUser: PropTypes.object,
    resetLoginState: PropTypes.func,
    openLoginPopup: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    setLoginManual: PropTypes.func,
    togglePaginationLoaderVisbility: PropTypes.func,
    updatedTenure: PropTypes.object,
    getCurrentSubscriptionInfo: PropTypes.func,
    selectedTenureValue: PropTypes.object,
    currentSubscription: PropTypes.object,
    validateSelectedPackResp: PropTypes.object,
    tenureAccountBalance: PropTypes.object,
    validateSelectedPack: PropTypes.func,
    addNewSubscription: PropTypes.func,
    modifyExistingSubscription: PropTypes.func,
    showMainLoader: PropTypes.func,
    hideMainLoader: PropTypes.func,
    fromLoginLoader: PropTypes.func,
    openMiniSubscription: PropTypes.func,
    selectedPlan: PropTypes.object,
    miniSubscription: PropTypes.object,
    showNotNowPopup: PropTypes.bool,
    setLiveUserLogin: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));