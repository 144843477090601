import store from "@src/store";
import { isUserloggedIn } from "@utils/common";
import { VARIANT_DETAILS } from "@utils/constants/feature";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

/**
 * Finds the default variant of a given feature based on its key.
 *
 * @param {Array} data - The list of features.
 * @param {string} featureKey - The key of the feature to find.
 * @returns {Object|null} The default variant object, or null if not found.
 */
export const getVariantTestKey = (data, featureKey) => {
    const feature = data.find(item => item.featureKey === featureKey); // Find the feature by its key

    if (!feature || !feature.assignedTestVariant) {
        return null; // Return null if the feature or its assignedTestVariant is not found
    }

    // Return the testKey of the assignedTestVariant
    return feature?.assignedTestVariant?.testKey || null;
};

export const getExperimentKey = (data, featureKey) => {
    // Find the feature by its key
    const feature = data.find(item => item.featureKey === featureKey);

    if (!feature || !feature.experimentKey) {
        return null; // Return null if the feature or its experimentKey is not found
    }

    // Return the experimentKey of the feature
    return feature.experimentKey || null;
};


/**
 * Gets all feature keys and their default variants for guest users.
 *
 * @param {Array} data - The list of features.
 * @returns {Array} List of feature keys with their default variants.
 */
export const getDefaultVariantsForGuest = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
        return []; // Return an empty array if input data is invalid or empty
    }

    return data.map(feature => {
        // Extract the default variant for the feature
        const defaultVariant = feature.testVariants.find(variant => variant.inProduction);

        return {
            featureKey: feature.featureKey,
            assignedTestVariant: defaultVariant || null, // Include the default variant or null
            experimentKey: feature.experimentKey,
        };
    }).filter(item => item.defaultVariant !== null); // Exclude features without a default variant
};



export const getVariantTestKeyWithPlaceholder = (data, featureKey, placeholder = null) => {
    // Find the feature by its key
    const feature = data.find(item => item.featureKey.toLowerCase() === featureKey.toLowerCase());

    if (!feature || !feature.assignedTestVariant) {
        return null; // Return null if the feature or its assignedTestVariant is not found
    }

    // Check if placeholder matches, if provided
    if (placeholder && feature.placeholder !== placeholder) {
        return null; // Return null if the placeholder does not match
    }

    // Return the testKey of the assignedTestVariant
    return feature.assignedTestVariant.testKey || null;
};

/**
 * @function - handleExperimentEvent to track experiment event whenever experiment is triggered
 * @param {*} params 
 */
export const handleExperimentEvent=(params = {})=>{
    let eventParam = {
        [`${MIXPANEL.PARAMETER.EXPERIMENT_NAME}`]: params?.experimentName,
        [`${MIXPANEL.PARAMETER.VARIANT_NAME}`]: params?.variant,
    };
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.EXPERIMENT_STARTED, eventParam);
 };

/**
 * @function getSelectedVariant - to get assigned varient of selected feature of experiment
 * @param {*} featureKey - string - name of feature 
 * @returns - assigned varient w.r.t to experiment and default handling
 */
export const getSelectedVariant = (featureKey) => {
    const state = store.getState();
    const { assignedExperimentData, appFeatureListing } = state?.headerDetails || {};
    const getTestKey = (data) => getVariantTestKey(data || [], featureKey);

    if ( isUserloggedIn() && assignedExperimentData?.length > 0 && assignedExperimentData.some((data) => data.featureKey.toLowerCase() === featureKey.toLowerCase())
    ) {
      // Fetch varient key for logged-in users on which any experiment is ON on feature as per list received in assigned varient API
      const varientVal = getTestKey(assignedExperimentData);
      const experimentKey = getExperimentKey(assignedExperimentData, featureKey);
      let params = {
        experimentName: experimentKey,
        variant: varientVal,
      }
      handleExperimentEvent(params);
  
      return varientVal;

    } else {
     // when user is guest user or no experiment is running on logged in user
      if (appFeatureListing?.length > 0) {
        let resData = getDefaultVariantsForGuest(appFeatureListing);
        const varientVal = getTestKey(resData);
        return varientVal;
      }
    }
    return "";
  };

  /**
   * @function - getSelectedVarientURL - to get url of selected varient specif to feature
   * @param {*} featureName - name of feature for which varient URL is needed
   * @param {*} activeVarient - varient received from BE to be used
   * @returns - URl string of asked variant
   */
  export const getSelectedVariantURL = (featureName, activeVariant) => {
    let selectedFeatureDetails = Object.entries(VARIANT_DETAILS).find(([key]) => key.toLowerCase() === featureName.toLowerCase()),
        selectedVariantUrl = selectedFeatureDetails && selectedFeatureDetails?.[1] && selectedFeatureDetails[1].find((i) => i?.variant?.toLowerCase() === activeVariant?.toLowerCase());
    return selectedVariantUrl?.url ? selectedVariantUrl?.url : "";
};

/**
 * @function - getFeatureExperimentDetails
 * @description - Checks if any experiment is running for the given feature and returns its details.
 * @param {string} featureName - The name of the feature to check for an experiment.
 * @returns {Object} - An object containing experimentName and variantName if an experiment is running, else an empty object.
 */
export const getFeatureExperimentDetails = (featureName) => {
    const state = store.getState();
    const { assignedExperimentData } = state?.headerDetails || {};

    if (
        isUserloggedIn() &&
        assignedExperimentData?.length > 0 &&
        assignedExperimentData.some((data) => data.featureKey.toLowerCase() === featureName.toLowerCase())
    ) {
        // Fetch experiment name and variant for logged-in users if an experiment is running
        const experimentName = getExperimentKey(assignedExperimentData, featureName);
        const variantName = getVariantTestKey(assignedExperimentData, featureName);

        return {
            [`${MIXPANEL.PARAMETER.EXPERIMENT_NAME}`]: experimentName || "",
            [`${MIXPANEL.PARAMETER.VARIANT_NAME}`]: variantName || "",
        };
    }

    return {}; // Return empty object if no experiment is running
};

