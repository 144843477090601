import CONSTANT from "@environment/index";

export const FEATURES = {
    SEARCH_FEATURE: "SEARCH-FEATURE",
    AUTO_SEARCH_SUGGESTION_FEATURE: "AUTO-SEARCH-SUGGESTION-FEATURE",
};

export const VARIENT_VALUE = {
    VARIANT_1: 'variant-1',
    VARIANT_2: 'variant-2',
};

export const VARIANT_DETAILS = {
    "SEARCH-FEATURE": [
        {
            variant: VARIENT_VALUE.VARIANT_1,
            url: CONSTANT.SEARCH_ELASTIC,
        },
        {
            variant: VARIENT_VALUE.VARIANT_2,
            url: CONSTANT.SEARCH_GEMINI,
        },
    ],
    "AUTO-SEARCH-SUGGESTION-FEATURE": [
        {
            variant: VARIENT_VALUE.VARIANT_1,
            url: CONSTANT.AUTO_SUGGESTION,
        },
        {
            variant: VARIENT_VALUE.VARIANT_2,
            url: CONSTANT.AUTO_SUGGESTION_GEMINI,
        },
    ],
};
