import store from "@src/store";
import { get, isEmpty } from "lodash";

import { openPopup, closePopup } from "@common/Modal/action";
import { MODALS } from "@common/Modal/constants";
import { getKey, setKey, deleteKey } from "@utils/storage";
import {
    LOCALSTORAGE,
    MESSAGE,
    SUBSCRIPTION_TYPE,
    ERROR_CODE,
    DTH_TYPE,
    WEB_SMALL_PAYMENT_SOURCE,
    SUBSCRIPTION_TYPE_HEADER,
    PACK_TYPE,
    SILENT_LOGIN_PLATFORM,
    SUBSCRIPTION_EXTERNAL_SOURCES,
} from "@constants";
import { addNewSubscription, modifyExistingSubscription, validateSelectedPack, cancelSubscription, getCurrentSubscriptionInfo } from '../APIs/action';
import { quickRechargeForDTH, paymentThroughTSWallet } from '../../SubscriptionPayment/APIs/action';

import { ACCOUNT_STATUS, SUBSCRIPTION_STATUS, PRIME_STATUS, DISCOUNT_SOURCE, JOURNEY_SOURCE, DISCOUNT_PERCENTAGE, DISCOUNT_FIXED, RETRY_CASE_SUCCESS_SCREEN_ERROR } from './constant';
import {
    safeNavigation, 
    trackMixpanelSubsciptionInitiate,
    getCurrentSubscriptionTenureType,
    getPartnerSubscriptionInfo,
    setSubscriptionJourneySource,
    deletePaymentKeysFromLocal,
    trackMixpanelPaymentEventNonFreemiumDthUser,
} from "@utils/common";
import { URL } from "@constants/routeConstants";
import MIXPANEL from "@constants/mixpanel";
import mixPanelConfig from "@utils/mixpanel";
import { DTH_PAYMENT_STATUS } from "@containers/SubscriptionPayment/APIs/constants";


/**
 * @function getComponentList - to fetch componentList for partner images from pack listing 
 * @param {*} item - single pack detail
 * @returns 
 */
export const getComponentList = (item) => {
    let componentList = item?.componentList?.find(packItem => packItem?.componentId === item?.productId);
    if (!componentList) {
        componentList = item?.componentList && item.componentList[0]
    }
    return componentList || [];
};


/**
 * @function isSubscriptionStatusEmpty - check current subscription to check if user does hold any subscription
 * @param {*} currentSubscription 
 * @returns boolean, This function will check if user does hold any subscription
 */
export const isSubscriptionStatusEmpty = () => {
    const state = store.getState(),
        currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {}),
        subscriptionStatus = get(currentSubscription, 'subscriptionStatus');

    return (subscriptionStatus === null || subscriptionStatus === undefined || isEmpty(subscriptionStatus));
};


/**
 * @function checkCurrentSubscription - check subscriotion based on renew and normal flow
 * @param {*} currentSubscription 
 * @returns boolean, if user is subscribed to any subscription or not , This function will return true if user has no current subscription else return false
 */
export const checkCurrentSubscription = (currentSubscription, isRenew = false) => {
    const isSubscriptionDeactive = get(currentSubscription, 'subscriptionStatus') === ACCOUNT_STATUS.DEACTIVE;

    return isRenew
        ? (isSubscriptionStatusEmpty() || (get(currentSubscription, 'freeTrialStatus') || isSubscriptionDeactive))
        : (isSubscriptionStatusEmpty() || (get(currentSubscription, 'freeTrialStatus') && isSubscriptionDeactive));;
};


/**
* @function isSubscriptionFreemium
* @returns boolen, is subscription of FREEMIUM type or not
 */
export const isSubscriptionFreemium = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    return (userInfo?.subscriptionType && userInfo?.subscriptionType.toUpperCase() === SUBSCRIPTION_TYPE.FREEMIUM);
};


/**
 * @function rechargeCheck - for user redirection on self care for recharge
 */
export const rechargeCheck = async () => {
    const state = store.getState();

    let subscriptionPaymentReducer = get(state?.subscriptionPaymentReducer, 'balanceInfo.data.recommendedAmount'),
        recommendedAmount = subscriptionPaymentReducer?.recommendedAmount;
    recommendedAmount = recommendedAmount === undefined ? 0 : recommendedAmount;

    await store.dispatch(quickRechargeForDTH(parseInt(recommendedAmount)));
    let quickRechargeSelfCareUrl = get(state?.subscriptionPaymentReducer, 'quickRecharge');
    if (quickRechargeSelfCareUrl?.code === 0 && !isEmpty(quickRechargeSelfCareUrl.data)) {
        window.location.assign(`${quickRechargeSelfCareUrl.data.rechargeUrl}`);
    } else {
        errorForAPIFailure(quickRechargeSelfCareUrl);
    }
};


/**
 * @function checkOpenFsFlow - based on local store values check user has silent login for unlock FS flow
 * @returns boolean
 */
export const checkOpenFsFlow = () => {
    let isUnlockFsFlow = getKey(LOCALSTORAGE.UNLOCKED_FS_FLOW) || false,
        silentLoginPlatform = getKey(LOCALSTORAGE.SILENT_LOGIN_PLATFORM) || '';

    return silentLoginPlatform === SILENT_LOGIN_PLATFORM.BINGE_OPEN_FS && isUnlockFsFlow;
};


/**
 * @function getSubscriptionPayload - prepare payload for subscription add/modify call 
 * @param {*} passedPayload passedPayload is payload when user is coming from renew flow in that case there are different handling for some payload values
 * @param {*} cartId cartId of order from manage app flow
 * @returns 
 */
export const getSubscriptionPayload = (passedPayload, retryPaymentPayload) => {
    const cartId = retryPaymentPayload?.cartId,
        passedPackId = retryPaymentPayload?.passedPackId,
        retryPostRecharge = retryPaymentPayload?.retryPostRecharge,
        retryPayment = retryPaymentPayload?.retryPayment;

    const state = store.getState(),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));

    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {}),
        isNonFreemiumDthUser = !isSubscriptionFreemium() && userInfo?.dthStatus !== DTH_TYPE.NON_DTH_USER,
        selectedTenure = get(state.subscriptionDetails, 'selectedTenureValue'),
        validateSelectedPackResp = get(state.subscriptionDetails, 'validateSelectedPackResp'),
        tenureAccountBalance = get(state.subscriptionDetails, 'tenureAccountBal'),
        existingUser = get(state.loginReducer, 'existingUser.data'),
        isManagedApp = get(state.headerDetails, "isManagedApp"),
        packListingData = get(state.subscriptionDetails, "packListingData"),
        isUnlockFsFlow = getKey(LOCALSTORAGE.UNLOCKED_FS_FLOW) || false,
        isMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        dthBalanceApiResponse = get(state?.subscriptionPaymentReducer, "balanceInfo.data"),
        availablePacks = get(state.subscriptionDetails, 'packListingData')

    const selectedPack = availablePacks?.find(p => p.productId === get(selectedTenure, 'tenureId')) || currentSubscription; //renewal if selectedPack is empty
    !isManagedApp && setKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK, JSON.stringify(selectedPack));
    // payload amt will be amount received in valiadte pack API, if not then prorated amount API resp, if not then selected tenure amount
    // There are  3 level checks are there for amount filed, because validate pack API is not getting called for non-freemium users and
    // if user is subscribed to any subscription then only we call prorated API and in case both checks fails we consider selected tenure amount
    let amountForpayload = get(validateSelectedPackResp, 'data.totalAmount') ?
        get(validateSelectedPackResp, 'data.totalAmount')?.toFixed(2)?.toString() :
        get(tenureAccountBalance, 'amount', selectedTenure?.offeredPriceValue);
   
    let preparedPayload = {
        sid: get(userInfo, 'sId'),
        baId: get(userInfo, 'baId'),
        packId: get(selectedTenure, 'tenureId') || passedPackId || '',
        amount: amountForpayload || "",
        startDate: get(validateSelectedPackResp, 'data.term.startDate', '') || "",
        endDate: get(validateSelectedPackResp, 'data.term.endDate', '') || "",
        return_url: `${window?.location?.origin}/${URL.SUBSCRIPTION_TRANSACTION_REDIRECT}`,
        deviceType: 'WEB',
        language: "ENGLISH",
        subscriptionType: get(userInfo, 'subscriptionType'),
        offerId: get(validateSelectedPackResp, 'data.discountList') && get(validateSelectedPackResp, 'data.discountList')[0]?.offerId,
        source: isUnlockFsFlow ? 'MOREFS' : !isEmpty(get(validateSelectedPackResp, 'data.discountList')) && DISCOUNT_SOURCE,
        cartId: cartId,
        externalSourceMSales: (!!existingUser?.externalSourceMSales || !!isMSales) ? true : false
    };

    //  preparedPayload is modified based on different flows 

   if (passedPayload){  // runs in case of renew subscription flow
        preparedPayload = { ...preparedPayload, ...passedPayload } 
    }

    let ssoLoginJourney = JSON.parse(getKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY)) === true;
    if (ssoLoginJourney) { // runs in case user is coming form SSO subscription payment journey like FS
        let payload = {
            packId: get(existingUser, 'productId') || get(passedPayload, 'packId'),
            amount: get(dthBalanceApiResponse, 'proRataAmount') || get(passedPayload, 'amount') ,
        };
        preparedPayload = { ...preparedPayload, ...payload };
    }
    else if (isNonFreemiumDthUser) {  // runs specifically to handle non-freemium and non-dth user renew flow
        let payload = {
            amount: get(tenureAccountBalance, 'amount', selectedTenure?.offeredPriceValue) ? get(tenureAccountBalance, 'amount', selectedTenure?.offeredPriceValue) : parseInt(get(currentSubscription, 'amountValue')).toFixed(2)?.toString(),
            packId: get(selectedTenure, 'tenureId') ? get(selectedTenure, 'tenureId') : get(currentSubscription, 'productId'),
        };
        preparedPayload = { ...preparedPayload, ...payload };
    };

    if(isUnlockFsFlow) {  // runs in case user is coming from unlock FS flow
        let unlockFsPayload = {
            newPackId: get(selectedTenure, 'tenureId') || '',
            currentPackId: get(currentSubscription, 'productId') || '',
        };
        preparedPayload = { ...preparedPayload, ...unlockFsPayload };
    }

    if (checkOpenFsFlow()) { // runs in case of open FS flow
        let getPackType = packListingData?.[0]?.packType === PACK_TYPE.FS;
        preparedPayload = {
            ...preparedPayload,
            ...(getPackType && { subscriptionType: SUBSCRIPTION_TYPE.DONGLE })
        };
    }

    if(!isEmpty(passedPackId) && retryPayment) { // runs in case of retry payment 
        let payload = {
            packId: passedPackId,
            retry: true
        };

        preparedPayload = { ...preparedPayload, ...payload };
    }

    let couponDetails = getKey(LOCALSTORAGE.COUPON_DETAILS);
    if (!isEmpty(couponDetails)) {
        let payload = JSON.parse(couponDetails);
        preparedPayload = { ...preparedPayload, ...payload };
    }
    if (retryPostRecharge) { // run in case of post quick recharge flow
        let payload = {
            retryPostRecharge: true
        };

        passedPackId && (payload["packId"] = passedPackId);
        preparedPayload = { ...preparedPayload, ...payload };
        deleteKey(LOCALSTORAGE.RECHARGE_JOURNEY_FROM_PG);
        let ssoLoginJourney = JSON.parse(getKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY)) === true;
        ssoLoginJourney && deleteKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY);
    }

    //final payload is returned
    return preparedPayload;
};


/**
 * @function checkAPIEligibility :- to check which subscription API is to be called add or modify
 * @returns boolean - basis checks for subscription and FS flow
 */
export const checkApiCallEligibility = () => {
    const state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {});

    // for FS productId is also checked for handling of their first purchase scenario
    let fsSpecificCheck = (isEmpty(currentSubscription?.productId) && get(currentSubscription, 'subscriptionStatus') === ACCOUNT_STATUS.DEACTIVE);

    // checkCurrentSubscription will check if user is subscribed to any subscription or not, if user has no subscription add subscription API will be called else modify 
    return checkOpenFsFlow() ? (isSubscriptionStatusEmpty() || fsSpecificCheck) : checkCurrentSubscription(currentSubscription, true);
};


/**
 * @function handleSubscriptionCall - to handle add/modify API call and its response for redirection of user to OPEL payment and DTH balance screens
 * @param {*} history 
 * @param {*} isFromMyPlan - to track from where user has started the renew flow so that after successful renewel we will land user on same flow
 * @param {*} passedPayload - payload keys are different for renew flow so managing it with this parameter
 * @param {*} cartId - cartId od manage app purachse order
 */
export const handleSubscriptionCall = async (history, isFromMyPlan = false, passedPayload, retryPaymentPayload, callback) => {
    const state = store.getState(),
          userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));

    let isNonFreemiumDthUser = !isSubscriptionFreemium() && userInfo?.dthStatus !== DTH_TYPE.NON_DTH_USER;

    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {}),
        selectedTenure = get(state.subscriptionDetails, 'selectedTenureValue'),
        validateSelectedPackResp = get(state.subscriptionDetails, 'validateSelectedPackResp'),
        availablePacks = get(state.subscriptionDetails, 'packListingData'),
        isManagedApp = get(state.headerDetails, "isManagedApp");
    
    let apiPayload = getSubscriptionPayload(passedPayload, retryPaymentPayload); // fetch prepared payload for all type of subscription flow
    let apiResponse;

    // selectedPack is set for pack details selected by user 
    // in FS flow or msales or mservice subscription when user does not select any pack from web SUBSCRIPTION_SELECTED_PACK is set in webSmallValidate from existingUser resp 
    let selectedPack = availablePacks?.find(p => p.productId === get(selectedTenure, 'tenureId')); 
    if(isEmpty(selectedPack) && !isEmpty(passedPayload)){
    // if selectedPack is empty then it may be case of renewal 
        selectedPack = currentSubscription
    } 
    !isManagedApp && !isEmpty(selectedPack) && setKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK, JSON.stringify(selectedPack));
    
    let checkAddModify = checkApiCallEligibility();

    if (checkAddModify) {
       await store.dispatch(addNewSubscription(apiPayload)).then(addNewPackRes => {
            apiResponse = addNewPackRes;
        }).catch(err => {
            console.log(`Error occoured while adding subscription: ${err}`)
        })
    } else {
        //send journeySourceRefId so that apple upgrade verbiage is seen in plan success screen
        apiPayload = {...apiPayload, journeySourceRefId: JSON.parse(getKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID))};
        await store.dispatch(modifyExistingSubscription(apiPayload)).then(modifyPackRes => {
            apiResponse = modifyPackRes;
        }).catch(err => {
            console.log(`Error occoured while adding subscription: ${err}`)
        })
    }
    !isEmpty(apiResponse?.data) && await selectedTenureUpdate(apiResponse)
    !isEmpty(apiResponse?.data) && await setKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK, JSON.stringify({ ...apiResponse?.data, amountValue: apiResponse?.data?.amount }));
    
    let transactionId = get(apiResponse, "data.paymentPayload.payload.orderId") ? get(apiResponse, "data.paymentPayload.payload.orderId") : get(apiResponse, "data.paymentTransactionId");
    setKey(LOCALSTORAGE.TRANSACTION_ID, transactionId);

    if(!isEmpty(apiResponse?.data)) {
        await selectedTenureUpdate(apiResponse);
        isManagedApp && await setKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK, JSON.stringify({ ...apiResponse?.data, amountValue: apiResponse?.data?.amount }));
    };
    
    if (checkOpenFsFlow()) {
        // while unlocked true is coming in url for ofs differnt flow handling is done 
        if(!isEmpty(apiResponse)){
            if (apiResponse?.data?.openFSQRScreenDetails !== null) {
                errorForAPIFailure(apiResponse?.data, rechargeCheck());
            } else {
                store.dispatch(getCurrentSubscriptionInfo());
                openSubscriptionSuccessModal(apiResponse, history, checkAddModify);
            }
        }
    }
    else {
        if (apiResponse?.code === RETRY_CASE_SUCCESS_SCREEN_ERROR) {
            callback(false, true)
        } else if (isEmpty(apiResponse?.data)) {
            let couponDetails = getKey(LOCALSTORAGE.COUPON_DETAILS);
            !isEmpty(couponDetails) && deleteKey(LOCALSTORAGE.COUPON_DETAILS);

            errorForAPIFailure(apiResponse);
        } else if (get(apiResponse, 'data.isPaymentNotRequested')) {
            // handling of direct screen of payment success screen without calling polling api in case if tik tik FDO requested jira id-26771
            setKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE, JSON.stringify(get(apiResponse, "data.paymentStatusVerbiage")));
            setKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE, JSON.stringify(get(apiResponse, "data.paymentErrorVerbiages")));
            safeNavigation(history, { pathname: `/${URL.SUBSCRIPTION_TRANSACTION_REDIRECT}`, state: {isPaymentNotRequest : get(apiResponse, 'data.isPaymentNotRequested')}  });
        }
        else if (get(apiResponse, 'data.dth')) {
            // if dth key is true then no payment SDK will be visible to user only balnace info will be there alonf with tatasky balance and make payment button
            safeNavigation(history, { pathname: `/${URL.BALANCE_INFO}` })
        } else if (!get(apiResponse, 'data.upFrontMoneyCollected')) {
            // upFrontMoneyCollected if false then we need to show payment SDK
            apiPayload?.offerId && setKey(LOCALSTORAGE.IS_PAYMENT_FROM_DISCOUNT, true);
            setKey(LOCALSTORAGE.IS_PAYMENT_FROM_SUBSCRIPTION, isFromMyPlan);
            safeNavigation(history, { pathname: `/${URL.SUBSCRIPTION_TRANSACTION}` })
        } else if (get(apiResponse, 'data.showPaymentSuccess')) {
            // handling of direct payment status screen in coupon redeem flow
            setKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE, JSON.stringify(get(apiResponse, "data.paymentStatusVerbiage")));
            setKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE, JSON.stringify(get(apiResponse, "data.paymentErrorVerbiages")));

            safeNavigation(history, { pathname: `/${URL.SUBSCRIPTION_TRANSACTION_REDIRECT}` });
        } else {
            store.dispatch(getCurrentSubscriptionInfo());
            if (isNonFreemiumDthUser) {
                trackMixpanelPaymentEventNonFreemiumDthUser();
            }
            openSubscriptionSuccessModal(apiResponse, history);
        }
    };

    !isEmpty(get(validateSelectedPackResp, 'data.discountList')) && setSubscriptionJourneySource(MIXPANEL.VALUE.DISCOUNTING_PAGE);

    !isNonFreemiumDthUser && trackMixpanelSubsciptionInitiate(currentSubscription, history?.location?.state?.prevPath, MIXPANEL);
};


/**
 * @function renewSusbcription - for handing of renew subscription button click
 * @param {*} history 
 * @param {*} isFromMyPlan - to track from where user has started the renew flow so that after successful renewel we will land user on same flow
 */
export const renewSusbcription = async (history, isFromMyPlan = false, isSourceRenewNudge = false) => {
    const state = store.getState(),
          user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));

    let isManagedApp = get(state.headerDetails, "isManagedApp"),
        currentSubscriptionInfo = get(state.subscriptionDetails, 'currentSubscription.data', {}),
        currentPack = get(currentSubscriptionInfo, 'tenure')?.find(item => item.currentTenure),
        id = get(currentPack, 'tenureId') ? get(currentPack, 'tenureId') : get(currentSubscriptionInfo, 'productId'),
        isNonFreemiumDthUser = !isSubscriptionFreemium() && user_info.dthStatus !== DTH_TYPE.NON_DTH_USER;

    let isMyopRenew = get(currentSubscriptionInfo, 'flexiPlan') && isManagedApp; // key send in payload of add/modify for the case renew tick tick case(as BE have to handle some scenrio for renew on there end)
    let isRenewNow = isSourceRenewNudge && isSubscriptionStatusEmpty(); // Send key to handle renew plan flow from nudge for users having no pack details avaialble.
    
    let payload = {
            amount: parseInt(get(currentSubscriptionInfo, 'amountValue')).toFixed(2)?.toString(),
            packId: get(currentSubscriptionInfo, 'productId'),
            startDate: '',
            endDate: '',
            isMyopRenew: isMyopRenew,
            renewNow: isRenewNow,
        };

    if (isNonFreemiumDthUser) {
        // if user is non-freemium i.e subscriptionType !== FREEMIUM and non dth user,
        // then in this case add and modify will be called on Make payment button click on balane info page
        setKey(LOCALSTORAGE.IS_NON_FREEMIUM_NON_DTH_SUBSCRIPTION_FLOW, true);
        safeNavigation(history, `${URL.BALANCE_INFO}`);

    } else if (isSubscriptionFreemium()) {
        if (isManagedApp) {
            handleSubscriptionCall(history, isFromMyPlan, payload); // for add/modify API handling
        } else {
            // if user is on FREEMIUM subscriptionType then first we will call validate pack API then add/modify
            await store.dispatch(validateSelectedPack(id));

            const state = store.getState();
            let validateSelectedPackResp = get(state.subscriptionDetails, 'validateSelectedPackResp');

            if (validateSelectedPackResp?.code === 0 && !isEmpty(validateSelectedPackResp?.data)) {
                let payloadFromValidate = {
                    amount: get(validateSelectedPackResp, 'data.totalAmount')?.toFixed(2)?.toString(),
                    startDate: get(validateSelectedPackResp, 'data.term.startDate'),
                    endDate: get(validateSelectedPackResp, 'data.term.endDate'),
                }
                payload = { ...payload, ...payloadFromValidate };
                handleSubscriptionCall(history, isFromMyPlan, payload); // for add/modify API handling
            }
            else {
                validateSelectedPackResp?.response?.status !== 500 && errorForAPIFailure(validateSelectedPackResp);
            }
        }
    } else {
        handleSubscriptionCall(history, isFromMyPlan, payload); // for add/modify API handling
    };
};


/**
 * @function openSubscriptionSuccessModal
 * @param {*} apiResponse - response of add/modify api
 * @param {*} history - history object
 * @returns - will open success popup after subscription success
 */
export const openSubscriptionSuccessModal = (apiResponse, history, status) => {
    const state = store.getState();
    let sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        sourceISMService = getKey(LOCALSTORAGE.SUBSCRIPTION_EXTERNAL_SOURCE) === SUBSCRIPTION_EXTERNAL_SOURCES.M_SERVICE,
        appsFlyerRedirectionUrl = get(state.headerDetails, "configResponse.data.config.appsflyerRedirectionLink"),
        instructions = status ? get(apiResponse, 'data.transMessage') : get(apiResponse, 'data.paymentStatusVerbiage.message');

    store.dispatch(openPopup(MODALS.ALERT_MODAL, {
        modalClass: 'alert-modal error-state-modal plan-success-modal',
        headingMessage: get(apiResponse, 'data.paymentStatusVerbiage.header'),
        extraInstructions: get(apiResponse, 'data.paymentStatusVerbiage.footer'),
        instructions: instructions,
        primaryButtonText: sourceIsMSales ? "" : 'Ok',
        primaryButtonAction: () => {
            closePopup();
            sourceISMService ? window.open(appsFlyerRedirectionUrl, '_blank') : safeNavigation(history, `${URL.DEFAULT}`)
        },
        hideCloseIcon: true,
        icon: true,
    }));
};


/**
 * @function errorForAPIFailure - to open error popup for API failure
 * @param {*} response 
 * @param {*} callBack 
 */
export const errorForAPIFailure = (response, callBack) => {
    store.dispatch(openPopup(MODALS.ALERT_MODAL, {
        modalClass: 'alert-modal error-state-modal',
        headingMessage: MESSAGE.ERROR_OCCURRED,
        instructions: response?.message ? response.message : MESSAGE.NO_DATA,
        primaryButtonText: 'Ok',
        primaryButtonAction: () => {
            callBack ? callBack() : closePopup()
        },
        errorIcon: 'icon-alert-upd',
        hideCloseIcon: true,
        errorCode: response?.code,
    }));
};

/**
 * @function getPackAmount - internal func to get amount from a patterned response
 * @param {*} amount 
 * @returns number
 */
const getPackAmount = (amount) => {
    return amount?.split(';')[1]?.split('/')[0];
};


/**
 * @function checkLowerPack - return the lowest pack containing specific provider
 * @param packName
 */
export const checkLowerPack = (packName) => {
    const state = store.getState()
    let packListingData = get(state.subscriptionDetails, 'packListingData');
    let packListContainingPartner = packListingData.filter(packItem => {
        let componentList = getComponentList(packItem);
        return !!get(componentList, 'partnerList').find(item => item?.partnerName?.toLowerCase() === packName?.toLowerCase() && item?.included);
    });
    packListContainingPartner = packListContainingPartner?.length > 1 ? packListContainingPartner.sort((packA, packB) => {
        let packAPrice = getPackAmount(get(packA, 'amount')), packBPrice = getPackAmount(get(packB, 'amount'))
        return packAPrice - packBPrice
    }) : packListContainingPartner;
    return !isEmpty(packListContainingPartner) && packName ? packListContainingPartner[0] : null;
};


/**
 * @function getHigherPack - get details of highest priced pack in listing
 * @returns object of higher pack price pack
 */
export const getHigherPack = () => {
    const state = store.getState()
    let packListingData = get(state.subscriptionDetails, 'packListingData');
    let sortedPackData = [...packListingData];
    sortedPackData.sort((packA, packB) => {
        let packAPrice = getPackAmount(get(packA, 'amount')), packBPrice = getPackAmount(get(packB, 'amount'))
        return packBPrice - packAPrice;
    });
    return !isEmpty(sortedPackData) ? sortedPackData[0] : null;
};


/**
 * @function getAnalyticsData - common function for subscription events data
 * @param {*} analytics 
 * @returns object of analytics data
 */
export const getAnalyticsData = (analytics = MIXPANEL) => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription', {});
    let isSubscriptionExpired = currentSubscription.data?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.DEACTIVE;
    // let daysRemaining = isSubscriptionExpired ? currentSubscription.data?.subscriptionNudgesDetails?.expiryDaysLeft : currentSubscription.data?.subscriptionNudgesDetails?.currentDay
    const daysRemaining = isSubscriptionExpired ? 0 : currentSubscription.data?.subscriptionNudgesDetails?.expiryDaysLeft;
    return {
        [`${analytics.PARAMETER.PACK_NAME}`]: currentSubscription?.data?.productName || "",
        [`${analytics.PARAMETER.DAYS_REMAINING}`]: daysRemaining ? Math.abs(daysRemaining) : 0,
        [analytics.PARAMETER.TENURE]: getCurrentSubscriptionTenureType(currentSubscription?.data)
    };
};

/**
 * @function handleCancelActivePlan handling of subscription cancellation
 * * @param {*} cancelPrime
 * * @param {*} cancelCurrentSub
 * @param {*} callback  - handle duplicate cancellation requests getting triggered
 */
export const handleCancelActivePlan = async (cancelPrime, cancelCurrentSub, callback=()=>{}) => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data', {});
    if (get(currentSubscription, 'migrated')) {
        callback();
        return store.dispatch(openPopup(MODALS.ALERT_MODAL, {
            modalClass: 'alert-modal error-state-modal cancel-plan-error',
            headingMessage: get(currentSubscription, 'migratedVerbiage') || MESSAGE.OPERATION_NOT_COMPLETED,
            primaryButtonText: 'Ok',
            primaryButtonAction: () => store.dispatch(closePopup()),
            hideCloseIcon: true,
        }))
    }
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const payload = {
        baId: get(user_info, 'baId'),
        accountId: get(user_info, 'sId'),
        primeCancellation: cancelPrime,
        bingeCancellation: cancelCurrentSub,
    }
    await store.dispatch(cancelSubscription(payload));
    callback();
    state = store.getState();
    let cancelSubscriptionRes = get(state.subscriptionDetails, 'cancelSubscriptionRes');
    if (cancelSubscriptionRes?.code === 0) {
        store.dispatch(closePopup());
        store.dispatch(openPopup(MODALS.ALERT_MODAL, {
            modalClass: 'alert-modal error-state-modal cancel-plan',
            headingMessage: get(cancelSubscriptionRes, 'data.deactivateMessage.header'),
            extraInstructions: get(cancelSubscriptionRes, 'data.deactivateMessage.footer'),
            instructions: get(cancelSubscriptionRes, 'data.deactivateMessage.message'),
            primaryButtonText: 'Done',
            primaryButtonAction: () => {
                store.dispatch(closePopup());
                store.dispatch(getCurrentSubscriptionInfo());
            },
            hideCloseIcon: true,
            icon: true,
        }))
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.MY_PLAN_CANCEL_PLAN_PROCEED, getAnalyticsData(MIXPANEL));
    } else if (!isEmpty(cancelSubscriptionRes)) {
        store.dispatch(closePopup())
        cancelSubscriptionRes?.response?.status !== ERROR_CODE.ERROR_500 && errorForAPIFailure(cancelSubscriptionRes)
    }
};


/**
 * @function isPrimeVisible - to check visibility of prime pack details
 * @returns 
 */
export const isPrimeVisible = () => {
    let state = store.getState();
    let primePackDetails = get(state.subscriptionDetails, 'currentSubscription.data.primePackDetails', {});
    let bundleState = get(primePackDetails, 'bundleState');
    return !!(primePackDetails && (bundleState?.toUpperCase() === PRIME_STATUS.ACTIVATED || bundleState?.toUpperCase() === PRIME_STATUS.SUSPENDED));
};

/**
 * @function journeySourceHeader 
 * @param {*} partnerData 
 * @param {*} checkPlans 
 */
export const journeySourceHeader = (partnerData, checkPlans) => {
    if (!isEmpty(partnerData)) {
        setKey(LOCALSTORAGE.JOURNEY_SOURCE, JOURNEY_SOURCE.HOME_CONTENT);
        setKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID, JSON.stringify(get(partnerData, 'partnerId')))
    } else if (checkPlans) {
        setKey(LOCALSTORAGE.JOURNEY_SOURCE, JOURNEY_SOURCE.DRAWER_CYOP);
        setKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID, JSON.stringify(""))
    } else {
        setKey(LOCALSTORAGE.JOURNEY_SOURCE, JOURNEY_SOURCE.DRAWER_MYOP);
        setKey(LOCALSTORAGE.JOURNEY_SOURCE_REF_ID, JSON.stringify(""))
    }
};

/**
 * @function tickTickAnalytics - mixpanel event tick tick
 * @param {*} event 
 * @param {*} source 
 */
export const tickTickAnalytics = (event, source) => {
    mixPanelConfig.trackEvent(event, {
        [`${MIXPANEL.PARAMETER.SOURCE}`]: source,
    });
};


/**
 * @function checkPartnerSubscriptionType - Checks if subscription is expired or not and if not then is the partner passed is subscribd with the pack or not
 * @param {*} providerId 
 * @returns string - subscription type
 */
export const checkPartnerSubscriptionType = (providerId) => {
    let state = store.getState();
    let userAccountStatus = get(state, "subscriptionDetails.currentSubscription.data.subscriptionStatus");
    let packExpired = userAccountStatus && userAccountStatus?.toUpperCase() !== SUBSCRIPTION_STATUS.ACTIVE;

    if (packExpired) {
        return SUBSCRIPTION_TYPE_HEADER.EXPIRED;
    } else {
        let result = getPartnerSubscriptionInfo(),
            subscribed, partnerList = getComponentList(result)?.partnerList;

        subscribed = partnerList &&
            partnerList.some((i) => {
                return parseInt(i.partnerId) === parseInt(providerId);
            });

        return subscribed ? SUBSCRIPTION_TYPE_HEADER.SUBSCRIBED : SUBSCRIPTION_TYPE_HEADER.FREEMIUM;
    }
};


/**
 * @function isUserEligibileForDiscount - check user's eligibility for discount from flag recieved in pack list resp
 * @param {*} packData 
 * @returns boolean
 */
export const isUserEligibileForDiscount = (packData) => {
    let filterDiscountedItems = packData.filter(data => data.discountList && data.discountList.length > 0)
    return filterDiscountedItems.length > 0;
};


/**
 * @function discountCalculate - returns eligible discountValue
 * @param {*} discountList 
 * @param {*} packAmount 
 * @returns 
 */
export const discountCalculate = (discountList, packAmount) => {
    // if (discountList[0]?.discountType === DISCOUNT_FIXED) {
    //   let discountAmount = get(discountList[0], "discountAmount");
    //   return Math.round((discountAmount / Number(packAmount)) * 100);
    // }
    return get(discountList[0], "discountValue");
};


/**
 * @function tenureDiscountVerbiage - verbiage for discount
 * @param {*} selectedPlan 
 * @returns string
 */
export const tenureDiscountVerbiage = (selectedPlan) => {
    //   if (selectedPlan?.discountList[0]?.discountType === DISCOUNT_FIXED) {
    //     return `You save ${discountCalculate(selectedPlan?.discountList, selectedPlan?.amountValue?.split(".")[0])}%`;
    //   }
    return get(selectedPlan, "tenure")[0]?.discountVerbaige;
};


/**
 * @function selectedTenureUpdate - select tenure value in local store
 * @param {*} apiResponse 
 */
export const selectedTenureUpdate = (apiResponse) => {
    let selectedData = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    setKey(
        LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK,
        JSON.stringify({
            ...selectedData,
            tenure: apiResponse?.data?.tenure,
            modificationType: apiResponse?.data.modificationType,
        })
    );
};


/**
 * @function handlePaymentRetry - handling of payment retry functionality
 * @param {*} history 
 */
export const handlePaymentRetry = (history, callback, retryPayment, retryPostRecharge) => {
    let cartId = getKey(LOCALSTORAGE.CART_ID),
        selectedPackDetails = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    let retryPaymentPayload = {};
    if (!isEmpty(cartId)) {
        // runs in case of manage app when cartId is stored 
        retryPaymentPayload["cartId"] = cartId;
    }
    else if (!isEmpty(selectedPackDetails)) {
        // runs in case of silent login scenarios and native end to end subscription journey and renew flow
        retryPaymentPayload["passedPackId"] = selectedPackDetails?.productId;
    };

    retryPostRecharge && (retryPaymentPayload["retryPostRecharge"] = retryPostRecharge);
    retryPayment && (retryPaymentPayload["retryPayment"] = retryPayment);

    handleSubscriptionCall(history, false, {}, retryPaymentPayload, callback);
};

/**
 * @function checkIsFirstSubscription - based on firstPaidPackSubscriptionDate recied in current and add/modify resp , same as android
 * @param {*} selectedPackData - detils of selected pack for firstPaidPackSubscriptionDate key
 */
 export const checkIsFirstSubscription = () => {
    let subscriptionModifyData = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK)),
    userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
    isNonFreemiumDthUser = !isSubscriptionFreemium() && userInfo?.dthStatus !== DTH_TYPE.NON_DTH_USER;

    return isNonFreemiumDthUser ? false : subscriptionModifyData?.firstPaidPackSubscriptionDate === null || isEmpty(subscriptionModifyData?.firstPaidPackSubscriptionDate);
};

export const paymentFromTataPlayWallet = async (history, retryPostRecharge, current) => {
    let state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        addNewPackRes = get(state.subscriptionDetails, 'addNewPackRes'),
        modifyPackRes = get(state.subscriptionDetails, 'modifyPackRes'),
        existingUser = get(state.loginReducer, 'existingUser'),
        validateSelectedPackResp = get(state.subscriptionDetails, 'validateSelectedPackResp');

    current?.setState({
        isTsWalletButtonDisabled: true
    });

    let response = checkCurrentSubscription(currentSubscription, true)
        ? get(addNewPackRes, "data")
        : get(modifyPackRes, "data");

    let ssoLoginJourney = JSON.parse(getKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY)) === true,
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)),
        isRenewSilentLogin = JSON.parse(getKey(LOCALSTORAGE.IS_RENEW_SILENT_LOGIN)) === true;

    if (ssoLoginJourney && !isRenewSilentLogin) {
        response = get(existingUser, "data");
    }


    if (retryPostRecharge) {
        response = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    }
    let couponDetails = JSON.parse(getKey(LOCALSTORAGE.COUPON_DETAILS));
    let couponCode = couponCode = couponDetails?.couponCode;

    const payload = {
        billingAmount: get(response, 'amount'),
        productId: get(response, 'productId'),
        productName: get(response, 'productName'),
        subscriberId: get(userInfo, 'sId'),
        baId: get(userInfo, 'baId'),
        paymentTransactionId: ssoLoginJourney ? get(response, 'paymentPayload.payload.orderId') : get(response, 'paymentTransactionId'),
        offerId: get(validateSelectedPackResp, 'data.discountList') && get(validateSelectedPackResp, 'data.discountList')[0]?.offerId,
        source: !isEmpty(get(validateSelectedPackResp, 'data.discountList')) && DISCOUNT_SOURCE,
        couponCode: !isEmpty(couponCode) ? couponCode : null
    }

    let tsWalletResponse = await store.dispatch(paymentThroughTSWallet(payload));
    retryPostRecharge && deleteKey(LOCALSTORAGE.RECHARGE_JOURNEY_FROM_PG);

    if (tsWalletResponse.code !== 0) {
        current?.setState({
            isTsWalletButtonDisabled: false
        })
    }
    let status = tsWalletResponse?.code === 0 ? DTH_PAYMENT_STATUS.CHARGED : DTH_PAYMENT_STATUS.FAILED;
    safeNavigation(history, {
        pathname: `/${URL.SUBSCRIPTION_TRANSACTION_REDIRECT}`,
        state: {
            walletStatus: status
        },
    });
};