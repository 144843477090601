import PubNub from 'pubnub';
import get from 'lodash/get';
import { getKey, setKey } from "@utils/storage";
import { LOCALSTORAGE } from "@constants";
import ENV_CONFIG from '@config/environment/index';
import { getMediaReadyConfig, getPubnubChannelName, handlePubnubData, isUserloggedIn } from "@utils/common";
import store from '@src/store';
import { getLatestEventFromPubnub } from '@components/Header/APIs/actions';

let pubnub = null;
let pubnubMediaReady = null;
let pubNubListener = null;

export default (channel, callback, login, mediaReadyChannel) => {
    init(mediaReadyChannel);
    if (pubnub || pubnubMediaReady) {
        subscribe(channel, mediaReadyChannel);
        setListener(callback, mediaReadyChannel);
        isUserloggedIn() && !login && !mediaReadyChannel && fetchMessages();
    }
}

const init = (mediaReadyChannel) => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        mediaReadyConfig = getMediaReadyConfig(),
        mediaReadyChannelUuid = get(mediaReadyConfig, 'binge_web.uuid'),
        uuid = mediaReadyChannel ? mediaReadyChannelUuid : get(userInfo, 'sId'),
        pubnubData = {
            publishKey: ENV_CONFIG.PUBNUB.PUBLISH_KEY,
            subscribeKey: ENV_CONFIG.PUBNUB.SUBSCRIBE_KEY,
            uuid: uuid,
            origin: "tatasky.pubnubapi.com",
        };
    if (mediaReadyChannel) {
        pubnubMediaReady = new PubNub(pubnubData);
    } else {
        pubnub = new PubNub(pubnubData);
    }
};

const subscribe = (channel, mediaReadyChannel) => {
    if (mediaReadyChannel) {
        pubnubMediaReady.subscribe({
            channels: [channel],
        });
    } else {
        console.log(`Pubnub channel subscribed : ${channel}`);
        setKey(LOCALSTORAGE.CURRENT_PUBNUB_CHANNEL, channel);
        pubnub.subscribe({
            channels: [channel],
        });
    }
}

const setListener = (callback, mediaReadyChannel) => {
    if (mediaReadyChannel) {
        pubnubMediaReady.addListener({
            message: function (data) {
                callback(data.message);
            },
        });
    } else {
        pubNubListener = pubnub.addListener({
            message: function (data) {
                callback(data.message);
            },
        });
    }
};

export const removePubNubListener = (channelToRemove) => {
    let channelName = channelToRemove ? channelToRemove : getPubnubChannelName();
    console.log(`Pubnub channel unsubscribed : ${channelName}`);
    if (pubnub) {
        pubNubListener && pubnub.removeListener(pubNubListener);
        pubNubListener = null;
        pubnub.unsubscribe({ channels: [channelName] });
        //pubnub.unsubscribeAll();
    }
};

export const fetchMessages = () => {
    let channelName = getPubnubChannelName();
    console.log(`Pubnub channel on history call: ${channelName}`);
    store.dispatch(getLatestEventFromPubnub()).then(res => {
        let data = res?.channels?.[channelName]?.[0]?.message;
        data && handlePubnubData(data, 'from history')
    }).catch(err => console.log("Error While fetch pubnub last event pushed", err));
}
