import { hideMainLoader, showMainLoader } from '@src/action';
import QrCodeLoginServiceInstance from './services';
import { ACTION } from './constants'

export const fetchQRLoginDetails = (qrCodeId, showLoader = false) => {
    return (dispatch) => {
        // Show loader if required
        showLoader && dispatch(showMainLoader());
    
        // Call the API service
        return QrCodeLoginServiceInstance.fetchQRLoginDetails(qrCodeId)
            .then((response) => {
                showLoader && dispatch(hideMainLoader());
                dispatch({
                    type: ACTION.FETCH_QRLOGIN_DETAILS,
                    apiResponse: response,
                });
                return response;
            })
            .catch((error) => {
                showLoader && dispatch(hideMainLoader());
                dispatch({
                    type: ACTION.FETCH_QRLOGIN_DETAILS,
                    apiResponse: error,
                });
                console.error("Error while fetching login details: ", error);
            });
    };
};

// Action to validate login code
export const validateQRLoginCode = (qrCodeId, redirectCode, showLoader = false) => {
    return (dispatch) => {
        showLoader && dispatch(showMainLoader());
        return QrCodeLoginServiceInstance.validateQRLoginCode(qrCodeId, redirectCode,)
            .then((response) => {
                showLoader && dispatch(hideMainLoader());
                dispatch({
                    type: ACTION.VALIDATE_QR_LOGIN_CODE,
                    apiResponse: response,
                });
                return response;
            })
            .catch((error) => {
                showLoader && dispatch(hideMainLoader());
                dispatch({
                    type: ACTION.VALIDATE_QR_LOGIN_CODE,
                    apiResponse: error,
                });
                console.error("Error while validating login code: ", error);
            });
    };
};

export const showQRCodeComponent = (value) => {
    return { type: ACTION.SHOW_QR_CODE_SCREEN, value }
};

export const setQRCodeId = (value) => {
    return { type: ACTION.SET_QR_CODE_ID, value }
};

export const setQRLoginJourneyData = (payload) => {
    return dispatch => {
        dispatch({ type: ACTION.SET_QR_CODE_DATA, payload })
    }
};

export const resetQRLoginDetails = () => {
    return dispatch => {
        dispatch({ type: ACTION.RESET_QR_LOGIN_DETAILS })
    }
};